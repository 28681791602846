import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import axiosInterceptor from "./auth/AxiosInterceptor";
import FactFindingForm from "views/fact-finding-views/fact-finding";

axiosInterceptor();

function App() {
  // useEffect(() => {
  //   //axiosInterceptor(); //no need to hold until component mount
  // }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views} />
            {/* <FactFindingForm /> */}
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
