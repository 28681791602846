const dev = {
  // API_ENDPOINT_URL: "https://portal.renting2buy.com.au/",
  API_ENDPOINT_URL: "http://localhost:3001/",
  WEB_PORTAL_URL: "http://d3fosx282ylwpu.cloudfront.net/app/Dashboard",
};

const prod = {
  // API_ENDPOINT_URL: "https://portal.renting2buy.com.au/",
  API_ENDPOINT_URL: "http://r2b-api.eyepax.info/",
  WEB_PORTAL_URL: "http://d3fosx282ylwpu.cloudfront.net/app/Dashboard",
};

const test = {
  API_ENDPOINT_URL: "/",
  WEB_PORTAL_URL: "http://d3fosx282ylwpu.cloudfront.net/app/Dashboard",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
