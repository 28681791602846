import {
  APP_PREFIX_PATH,
  DOCUMENT_REQ_PREFIX_PATH,
  USER_PREFIX_PATH,
} from "configs/AppConfig";
import { USER_ROLES } from "configs/UserRoleConfig";

// const dashBoardNavTree = [
//   {
//     key: "dashboard",
//     path: `${APP_PREFIX_PATH}`,
//     title: "sidenav.dashboard.title",
//     icon: "User-2",
//     permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//     breadcrumb: false,
//     submenu: [
//       {
//         key: "dashboard",
//         path: `${APP_PREFIX_PATH}/dashboard`,
//         title: "Overview",
//         icon: "MyProfile",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       {
//         key: "profile",
//         path: `${APP_PREFIX_PATH}/profile`,
//         title: "My Profile",
//         icon: "MyProfile",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       {
//         key: "scoring",
//         path: `${APP_PREFIX_PATH}/scoring`,
//         title: "Scoring",
//         icon: "Scoring",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       {
//         key: "documents",
//         path: `${APP_PREFIX_PATH}/documents`,
//         title: "Documents",
//         icon: "Application",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       {
//         key: "documenttasklist",
//         path: `${APP_PREFIX_PATH}/documenttasklist`,
//         title: "Document Tasks",
//         icon: "Application",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       {
//         key: "researchmatrix",
//         path: `${APP_PREFIX_PATH}/researchmatrix`,
//         title: "Research matrix",
//         icon: "Search1",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       // {
//       //   key: "dashboard",
//       //   path: `${APP_PREFIX_PATH}`,
//       //   title: "Overview",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "profile",
//       //   path: `${APP_PREFIX_PATH}/profile`,
//       //   title: "My Profile",
//       //   icon: "User-Groups1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "organizations",
//       //   path: `${APP_PREFIX_PATH}/organization`,
//       //   title: "Scorecard templates",
//       //   icon: "Organization1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       {
//         key: "factfinding",
//         path: `${APP_PREFIX_PATH}/factfinding`,
//         title: "Fact finding",
//         icon: "FactFinding",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       {
//         key: "budget",
//         path: `${APP_PREFIX_PATH}/budget`,
//         title: "Budget",
//         icon: "Budget",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       {
//         key: "expedite",
//         path: `${APP_PREFIX_PATH}/expedite`,
//         title: "Expedite",
//         icon: "Organization1",
//         permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//         breadcrumb: false,
//         submenu: [],
//       },
//       // {
//       //   key: "agentclients",
//       //   path: `${APP_PREFIX_PATH}/agentclients`,
//       //   title: "Agent Clients",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       //--------------staff portal-------------
//       // {
//       //   key: "general",
//       //   path: `${APP_PREFIX_PATH}/general`,
//       //   title: "General",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "agentapplication",
//       //   path: `${APP_PREFIX_PATH}/agentapplication`,
//       //   title: "Agent Application",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "bumpUpRequest",
//       //   path: `${APP_PREFIX_PATH}/bumpUpRequest`,
//       //   title: "BumpUpRequest",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "agentcommissions",
//       //   path: `${APP_PREFIX_PATH}/agentcommissions`,
//       //   title: "Agent Commissions",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "agentprofile",
//       //   path: `${APP_PREFIX_PATH}/agentprofile`,
//       //   title: "Agent Profile",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "addagent",
//       //   path: `${APP_PREFIX_PATH}/addagent`,
//       //   title: "Staff Add Agent",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "staffagentprofile",
//       //   path: `${APP_PREFIX_PATH}/staffagentprofile`,
//       //   title: "Staff Agent Profile",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "staffagents",
//       //   path: `${APP_PREFIX_PATH}/staffagents`,
//       //   title: "Staff Agents",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//       // {
//       //   key: "systemConfigurations",
//       //   path: `${APP_PREFIX_PATH}/systemConfigurations`,
//       //   title: "SystemConfigurations",
//       //   icon: "Dashboard1",
//       //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
//       //   breadcrumb: false,
//       //   submenu: [],
//       // },
//     ],
//   },
// ];

// const navigationConfig = [...dashBoardNavTree];

// export default navigationConfig;

const dashBoardNavTree = {
  main: [
    {
      key: "dashboard",
      path: `${APP_PREFIX_PATH}`,
      title: "sidenav.dashboard.title",
      icon: "User-2",
      permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
      breadcrumb: false,
      submenu: [
        // {
        //   key: "clientdashboard",
        //   path: `${APP_PREFIX_PATH}/clientdashboard`,
        //   title: "Overview",
        //   icon: "MyProfile",
        //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
        //   breadcrumb: false,
        //   submenu: [],
        // },
        {
          key: "profile",
          path: `${APP_PREFIX_PATH}/profile`,
          title: "My Profile",
          icon: "MyProfile",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "scoring",
          path: `${APP_PREFIX_PATH}/scoring`,
          title: "Scoring",
          icon: "Scoring",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "documents",
          path: `${APP_PREFIX_PATH}/documents`,
          title: "Documents",
          icon: "Application",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        // {
        //   key: "documenttasklist",
        //   path: `${APP_PREFIX_PATH}/documenttasklist`,
        //   title: "Tasks",
        //   icon: "Application",
        //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
        //   breadcrumb: false,
        //   submenu: [],
        // },
        {
          key: "researchmatrix",
          path: `${APP_PREFIX_PATH}/researchmatrix`,
          title: "Research matrix",
          icon: "Search1",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "factfinding",
          path: `${APP_PREFIX_PATH}/factfinding`,
          title: "Fact finding",
          icon: "FactFinding",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "budget",
          path: `${APP_PREFIX_PATH}/budget`,
          title: "Budget",
          icon: "Budget",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        // {
        //   key: "expedite",
        //   path: `${APP_PREFIX_PATH}/expedite`,
        //   title: "Expedite",
        //   icon: "Organization1",
        //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
        //   breadcrumb: false,
        //   submenu: [],
        // },
      ],
    },
  ],

  document: [
    {
      key: "document",
      path: `${DOCUMENT_REQ_PREFIX_PATH}`,
      title: "sidenav.dashboard.title",
      icon: "Application",
      permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
      breadcrumb: false,
      submenu: [
        {
          key: "drivers-licence",
          path: `${DOCUMENT_REQ_PREFIX_PATH}/drivers-licence`,
          title: "Drivers Licence",
          icon: "Application",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "pay-slip",
          path: `${DOCUMENT_REQ_PREFIX_PATH}/pay-slip`,
          title: "Pay Slip",
          icon: "Application",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "tax-invoice",
          path: `${DOCUMENT_REQ_PREFIX_PATH}/tax-invoice`,
          title: "Tax Invoice",
          icon: "Application",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "bank-statement",
          path: `${DOCUMENT_REQ_PREFIX_PATH}/bank-statement`,
          title: "Bank Statement",
          icon: "Application",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "dividend-statement",
          path: `${DOCUMENT_REQ_PREFIX_PATH}/dividend-statement`,
          title: "Dividend Statement",
          icon: "Application",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "financial-statement",
          path: `${DOCUMENT_REQ_PREFIX_PATH}/financial-statement`,
          title: "Financial Statement",
          icon: "Application",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "property-deed",
          path: `${DOCUMENT_REQ_PREFIX_PATH}/property-deed`,
          title: "Property Deed",
          icon: "Application",
          permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
          breadcrumb: false,
          submenu: [],
        },
      ],
    },
  ],

  client: [
    {
      key: "client",
      path: `${USER_PREFIX_PATH}`,
      title: "sidenav.dashboard.title",
      icon: "User-2",
      permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
      breadcrumb: false,
      submenu: [
        // {
        //   key: "expedite",
        //   path: `${USER_PREFIX_PATH}/expedite`,
        //   title: "Expedite",
        //   icon: "User",
        //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
        //   breadcrumb: false,
        //   submenu: [],
        // },
        //     // {
        //     //   key: "score",
        //     //   path: `${USER_PREFIX_PATH}/score`,
        //     //   title: "Score",
        //     //   icon: "Dashboard1",
        //     //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
        //     //   breadcrumb: false,
        //     //   submenu: [],
        //     // },
        //     // {
        //     //   key: "documents",
        //     //   path: `${USER_PREFIX_PATH}/documents`,
        //     //   title: "Documents",
        //     //   icon: "Application",
        //     //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
        //     //   breadcrumb: false,
        //     //   submenu: [],
        //     // },
      ],
    },
  ],
};

const navigationConfig = dashBoardNavTree;
export default navigationConfig;
