import {
  all,
  takeLeading,
  put,
  fork,
  call,
  takeEvery,
  select,
} from "redux-saga/effects";
import UserService from "services/UserService";
import { message } from "antd";

export function* getUserStatusSaga() {
  yield takeEvery("START_GET_USER_STATUS_DATA", function* (input) {
    try {
      let user = yield select((state) => state.user.user);
      const userId = user.id;
      const response = yield call(UserService.getUserStatus, userId);
      console.log("====================================");
      console.log("ffffffff", response.data.requests.actions);
      console.log("====================================");
      if (response?.data?.requests?.actions) {
        const userStatus = response?.data?.requests?.actions[0] || {};
        yield put({ type: "DONE_GET_USER_STATUS_DATA", data: userStatus });
      }
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_LOADING" });
      message.error(error.message);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getUserStatusSaga)]);
}
