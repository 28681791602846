
const initState = {
    matrixLoginData: {
        openLoginModal: false,
        openOtpModal: false
    }
}

const MatrixLogin = (state = initState, action) => {
    switch (action.type) {
        case 'OPEN_LOGIN_MODAL':
            return {
                ...state,
                openLoginModal: true
            }
        case 'CLOSE_LOGIN_MODAL':
            return {
                ...state,
                openLoginModal: false
            }
        case 'OPEN_OTP_MODAL':
            return {
                ...state,
                openOtpModal: true
            }
        case 'CLOSE_OTP_MODAL':
            return {
                ...state,
                openOtpModal: false
            }
        default:
            return state;
    }
}

export default MatrixLogin