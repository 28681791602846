import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { CLIENT_PREFIX_PATH } from "configs/AppConfig";

export const ResearchMatrixViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${CLIENT_PREFIX_PATH}/research-matrix`}
          component={lazy(() => import(`./research-matrix`))}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/bank`}
          component={lazy(() => import(`./bank/R2BBankDetails`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/bank/select`}
          component={lazy(() => import(`./bank/R2BSelectBank`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/bank/login`}
          component={lazy(() => import(`./bank/R2BLogIntoBank`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/bank/login/success`}
          component={lazy(() => import(`./bank/R2BConnectedBank`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/eligible`}
          component={lazy(() => import(`./bank/EligibleClient`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/faq`}
          component={lazy(() => import(`./bank/FAQ`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/eligible/view`}
          component={lazy(() => import(`./bank/EligibleClientNext`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/bank/confirmation`}
          component={lazy(() => import(`./bank/Confirmation`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/bank/payment/success`}
          component={lazy(() => import(`./bank/PaymentSuccess`))}
          exact={true}
        />
        <Route
          path={`${CLIENT_PREFIX_PATH}/not/eligible`}
          component={lazy(() => import(`./bank/NotEligible`))}
          exact={true}
        />
      </Switch>
    </Suspense>
  );
};

export default ResearchMatrixViews;
