
  const initState = {
    applications:[]
  }
    
  const Applications = (state = initState, action) => {
    switch (action.type) {
      case 'DONE_GET_APPLICATIONS':
        return {
            ...state,
            applications: action.data
        }
      default:
      return state;
    }
  }
  
  export default Applications