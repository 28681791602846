
const initState = {
    loading: false,
    message: '',
    showMessage: false,
    status: 'idle',
    userStatus: {},
    user: {}
  }
  
  const user = (state = initState, action) => {
      switch (action.type) {
        case 'DONE_CHECK_USER':
            return {
                ...state,
                loading: false,
                user: action.data
            }
        case 'DONE_GET_USER_STATUS_DATA':
            return {
                ...state,
                loading: false,
                userStatus: action.data
            }
        case 'RESET_STATUS':
            return {
                ...state,
                status: 'idle'
            }
        case 'STOP_LOADING':
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
      }
  }
  
  export default user