import {
  all,
  takeLeading,
  put,
  fork,
  call,
  takeEvery,
} from "redux-saga/effects";
import ResearchMetrixService from "services/ResearchMetrix";
import userService from "services/UserService";
import { message } from "antd";
import { eligibilityCalcSaga } from "./Bank";

export function* saveAnswersSaga() {
  yield takeLeading("START_SAVE_ANSWERS", function* (input) {
    console.log(
      "🚀 ~ file: ResearchMetrix.js ~ line 9 ~ yieldtakeLeading ~ input",
      input
    );
    try {
      const response = yield call(
        ResearchMetrixService.saveAnswers,
        input.data.data
      );
      console.log(
        "🚀 ~ file: ResearchMetrix.js ~ line 11 ~ yieldtakeLeading ~ response",
        response
      );
      if (input.data.data.email) {
        const inputBody = {
          email: input.data.data.email,
        };
        console.log(
          "🚀 ~ file: ResearchMetrix.js ~ line 15 ~ yieldtakeLeading ~ inputBody",
          inputBody
        );
        const user = yield call(userService.checkUser, inputBody);
        yield put({ type: "DONE_CHECK_USER", data: user.data });
        console.log(
          "🚀 ~ file: ResearchMetrix.js ~ line 18 ~ yieldtakeLeading ~ user",
          user
        );
        yield put({ type: "DONE_SAVE_ANSWERS", data: user.data });
        console.log("====================================");
        console.log("navigation", input.data);
        console.log("====================================");
        //input.data.navigate.push('/client/bank',user.data)
        //eligibilityCalcSaga();
        input.data.calcEligibility(input.data.navigate);
      }
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_LOADING" });
      message.error(error.message);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(saveAnswersSaga)]);
}
