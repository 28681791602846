import { Button, Col, Input, Row, Tooltip } from "antd";
import React from "react";
import { SaveFilled, CloseCircleFilled } from "@ant-design/icons";

const EditableRowAssests = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
}) => {
  return (
    <Row gutter={2}>
      <Col span={5}>
        <Input
          width={"100vh"}
          type="text"
          required="required"
          placeholder=""
          name="descriptionAsst"
          size="small"
          className="factfindingtextinput"
          value={editFormData.description}
          onChange={handleEditFormChange}
        ></Input>
      </Col>
      <Col span={4}>
        <Input
          width={"100vh"}
          type="text"
          required="required"
          placeholder=""
          name="ownerAsst"
          size="small"
          className="factfindingtextinput"
          value={editFormData.owner}
          onChange={handleEditFormChange}
        ></Input>
      </Col>
      <Col span={3}>
        <Input
          width={"100vh"}
          type="text"
          required="required"
          placeholder=""
          name="currentValueAsst"
          size="small"
          className="factfindingtextinput"
          value={editFormData.currentValue}
          onChange={handleEditFormChange}
        ></Input>
      </Col>
      <Col span={3}>
        <Input
          width={"100vh"}
          type="text"
          required="required"
          placeholder=""
          name="typeAsst"
          size="small"
          className="factfindingtextinput"
          value={editFormData.type}
          onChange={handleEditFormChange}
        ></Input>
      </Col>
      <Col span={3}>
        <Tooltip title="Save" color="#FB8C26">
          <Button
            htmlType="submit"
            size="medium"
            tool
            style={{ border: "none" }}
          >
            <SaveFilled />
          </Button>
        </Tooltip>
        <Tooltip title="Cancel" color="red">
          <Button
            size="medium"
            style={{ border: "none", marginRight: "5px" }}
            onClick={handleCancelClick}
          >
            <CloseCircleFilled />
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default EditableRowAssests;
