import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { CLIENT_v2_PREFIX_PATH } from "configs/AppConfig";

export const ResearchMatrixViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${CLIENT_v2_PREFIX_PATH}/research-matrix`}
          component={lazy(() => import(`./researchMatrix`))}
        />
      </Switch>
    </Suspense>
  );
};

export default ResearchMatrixViews;
