import axios from "axios"

const researchMetrixService = {}

researchMetrixService.getToken = async () =>  {
  return await axios.get("/banks/token", {})
}

researchMetrixService.saveAnswers = async (data) =>  {
  return await axios.post("/answers", data)
}


export default researchMetrixService