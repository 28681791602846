
const initState = {
	loading: false,
	message: '',
	showMessage: false,
	status: 'idle',
	institutions: [],
	eligibility: {
		housing: false,
		investment: false,
		apartment: false
	},
	payments: {
		surplus: 0,
		surplusInvest: 0
	}
}

const bank = (state = initState, action) => {
	switch (action.type) {
		case 'START_CREATE_USER':
			return {
				...state,
				loading: true,
				status: 'pending'
			}
		case 'DONE_CREATE_USER':
			return {
				...state,
				loading: false,
			}
		case 'RESET_STATUS':
			return {
				...state,
				status: 'idle'
			}
		case 'STOP_BANK_LOADING':
			return {
				...state,
				loading: false,
			}
		case 'START_BANK_LOADING':
			return {
				...state,
				loading: true,
			}
		case 'DONE_GET_INSTITUTIONS':
			return {
				...state,
				loading: false,
				institutions: action.data
			}
		case 'START_GENERATE_DOC':
			return {
				...state,
				loading: true,
				status: 'pending'
			}
		case 'DONE_GENERATE_DOC':
			return {
				...state,
				loading: false,
				status: 'idle'
			}
		case 'START_BANK_LOGIN':
			return {
				...state,
				loading: true,
			}
		case 'DONE_BANK_LOGIN':
			return {
				...state,
				loading: false,
			}
		case 'START_CREATE_PAYMENT_USER':
			return {
				...state,
				loading: true,
			}
		case 'DONE_CREATE_PAYMENT_USER':
			return {
				...state,
				loading: false,
			}
		case 'DONE_CALC_ELIGIBILITY':
			return {
				...state,
				eligibility: action.data,
			}
		case 'DONE_CALC_PAYMENT':
			return {
				...state,
				payments: action.data,
			}
		default:
			return state;
	}
}

export default bank