import React, { memo, useEffect, useState } from "react";
import { Avatar, Button, Progress } from "antd";
import {
  ArrowRightOutlined,
  RightCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import { ReactComponent as House } from "assets/svg/sideNav/house.svg";
import Axios from "axios";
import ErrorHandlingService from "services/ErrorHandlingService";
import { env } from "configs/EnvironmentConfig";
import { useHistory } from "react-router-dom";

const SideNavAvatar = () => {
  const history = useHistory();

  const [userData, setUserData] = useState({
    displayName: "user user",
    key: null,
    stage: 10,
  });

  useEffect(() => {
    Axios.get("users/profile")
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((error) => {
        ErrorHandlingService(error?.response.status);
      });
  }, []);

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-3 mt-5">
        <Avatar
          size={64}
          src={
            userData.key
              ? `${env.API_ENDPOINT_URL}documents/files/${encodeURIComponent(
                  userData.key
                )}`
              : ""
          }
          style={{
            color: "white",
            backgroundColor: "#ef6225",
            cursor: "pointer",
          }}
          onClick={() => history.push("/user")}
        >
          {userData.displayName
            .split(" ")
            .reduce((acc, name) => acc + name.charAt(0).toUpperCase(), "")}
        </Avatar>
      </div>
      <div
        className="stps-title-label"
        onClick={() => history.push("/user")}
        style={{ cursor: "pointer" }}
      >
        {userData.displayName}
      </div>
    </div>
  );
};

export default memo(SideNavAvatar);

// const mapStateToProps = ({ client }) => {
//     const { loading: clientLoading, client: clientData } = client

//     return {
//       clientLoading,
//       clientData
//     };
//   };
//   export default connect(mapStateToProps, null)(memo(SideNavAvatar));
