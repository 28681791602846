import React from "react";
import { Switch, Route } from "react-router-dom";
import FactFindingViews from "views/fact-finding-views";

export const FactFindingLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={FactFindingViews} />
      </Switch>
    </div>
  );
};

export default FactFindingLayout;
