import React from "react";
import { Switch, Route } from "react-router-dom";
import ResearchMatrixViews from "views/research-matrix-views";

export const ResearchMatrixLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={ResearchMatrixViews} />
      </Switch>
    </div>
  );
};

export default ResearchMatrixLayout;
