import {
  CHANGE_ORGANIZATIONS,
  CHANGE_LOCATIONS
} from '../constants/Main'

const initState = {
  organizations: [],
  locations: [],
}
  
const Main = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_ORGANIZATIONS:
      return {
          ...state,
          organization: action.payload
      }
    case CHANGE_LOCATIONS:
      return {
          ...state,
          locations: action.payload
      }
    default:
    return state;
  }
}

export default Main