import { all, put, select, fork, call, takeEvery } from "redux-saga/effects";
import paymentService from "services/Payment";
import { message } from "antd";
import {
  DEFAULT_CURRENCY,
  DEFAULT_PAYMENT_FREQUENCY,
} from "../../constants/paymentConstants";

export function* createPaymentMethodSaga() {
  yield takeEvery("START_CREATE_PAYMENT_METHOD", function* (input) {
    try {
      const { data, stripe, history, closeModal, paymentDate } = input;

      const { paymentMethod } = yield call(stripe.createPaymentMethod, data);
      let user = yield select((state) => state.user.user);

      const createCustomerBody = {
        email: user?.email,
        name: user?.firstName,
        description: "First stripe",
      };
      const customer = yield call(
        paymentService.createCustomer,
        createCustomerBody
      );
      console.log(
        "🚀 ~ file: Payment.js ~ line 26 ~ yieldtakeEvery ~ customer",
        customer
      );

      const attachPayMethodBody = {
        paymentMethodId: paymentMethod?.id,
        customerId: customer.data.id,
      };
      const attachPayMethod = yield call(
        paymentService.attachPaymentMethod,
        attachPayMethodBody
      );
      console.log(
        "🚀 ~ file: Payment.js ~ line 35 ~ yieldtakeEvery ~ attachPayMethod",
        attachPayMethod
      );

      yield call(paymentService.setDefaultPaymentMethod, attachPayMethodBody);

      console.log(
        "🚀 ~ file: Payment.js ~ line 30 ~ yieldtakeEvery ~ paymentMethod",
        attachPayMethod
      );

      yield put({ type: "DONE_CREATE_PAYMENT_METHOD" });
      closeModal();
      yield put({ type: "START_CREATE_SUBSCRIPTION", history, paymentDate });
    } catch (error) {
      console.log(
        "🚀 ~ file: Payment.js ~ line 49 ~ yieldtakeEvery ~ error",
        error
      );
      console.dir(error);
      yield put({ type: "ERROR_CREATE_PAYMENT_METHOD" });
      input.closeModal();
      message.error({
        content: "Invalid Payment Method",
        style: {
          zIndex: 11000,
        },
      });
    }
  });
}

export function* createSubscriptionSaga() {
  yield takeEvery("START_CREATE_SUBSCRIPTION", function* (input) {
    try {
      const { history, paymentDate } = input;
      let user = yield select((state) => state.user.user);
      const createCustomerBody = {
        email: user?.email,
        name: user?.firstName,
        description: "First stripe",
      };
      const customer = yield call(
        paymentService.createCustomer,
        createCustomerBody
      );

      const { id: customerId } = customer.data;

      const createProductPayload = {
        name: `${user.email}_product`,
        userId: user.id,
      };

      const productRes = yield call(
        paymentService.createProduct,
        createProductPayload
      );

      const payments = yield select((state) => state?.bank.payments);
      const planPayload = {
        // amount: Math.ceil(payments.total / payments.noOfMonths) * 100,
        amount: Math.ceil(payments.subValue) * 100,
        currency: DEFAULT_CURRENCY,
        interval: DEFAULT_PAYMENT_FREQUENCY,
        product: productRes.data.id,
      };
      console.log(
        "🚀 ~ file: Payment.js ~ line 75 ~ yieldtakeEvery ~ planPayload",
        planPayload
      );

      const { data } = yield call(paymentService.createPlan, planPayload);

      const subscriptionPayload = {
        customer: customerId,
        items: [
          {
            plan: data.id,
          },
        ],
        paymentStartOn: paymentDate,
      };

      const { id: subscription } = yield call(
        paymentService.createSubscription,
        subscriptionPayload
      );
      console.log(
        "🚀 ~ file: Payment.js ~ line 90 ~ yieldtakeEvery ~ subscription",
        subscription
      );

      yield put({ type: "DONE_CREATE_SUBSCRIPTION" });
      history.push("/client/bank/payment/success");
    } catch (error) {
      console.log("Error Message", error.message);
      //yield put({type: 'STOP_LOADING'})
      message.error(error.message);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(createPaymentMethodSaga), fork(createSubscriptionSaga)]);
}
