import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { routeConfig } from "configs/RouteConfig";
import FormattingService from "services/FormattingService";
import { intersection } from "lodash";

function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

function getAllowedRoutes() {
  const roles = [];
  const payload = FormattingService.parseJwt();
  roles.push(parseInt(payload["custom:role_id"]));
  return routeConfig.filter(({ permission }) => {
    if (permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, roles).length;
  });
}

export const AppViews = (props) => {
  const routes = getAllowedRoutes();
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {routes?.map((route) => {
          const { key, path, component, permission, ...rest } = route;
          return (
            <Route
              {...rest}
              key={key}
              path={path}
              component={lazy(() => import(`${component}`))}
            ></Route>
          );
        })}
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
