import React, { memo, useEffect, useState } from "react";
import { Avatar, Button, Progress } from "antd";
import {
  ArrowRightOutlined,
  RightCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import { ReactComponent as House } from "assets/svg/sideNav/house.svg";
import Axios from "axios";
import ErrorHandlingService from "services/ErrorHandlingService";
import { env } from "configs/EnvironmentConfig";
import { useHistory } from "react-router-dom";

const SideNavAvatar = () => {
  const history = useHistory();

  const [userData, setUserData] = useState({
    displayName: "user user",
    key: null,
    stage: 10,
    createdAt: new Date(),
    expectedPropertyDate: new Date(),
  });

  useEffect(() => {
    Axios.get("users/profile")
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((error) => {
        ErrorHandlingService(error?.response.status);
      });
  }, []);

  const ParseDate = (date) => new Date(date);

  const now = new Date();
  const percentage =
    Number(
      Math.round(now - ParseDate(userData.createdAt)) /
        Math.round(
          ParseDate(userData.expectedPropertyDate) -
            ParseDate(userData.createdAt)
        )
    ) * 100;
  console.log(
    "🚀 ~ file: SideNavAvatar.js ~ line 39 ~ SideNavAvatar ~ percentage",
    percentage
  );

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-3 mt-5">
        <Avatar
          size={64}
          src={
            userData.key
              ? `${env.API_ENDPOINT_URL}documents/files/${encodeURIComponent(
                  userData.key
                )}`
              : ""
          }
          style={{
            color: "white",
            backgroundColor: "#ef6225",
            cursor: "pointer",
          }}
          onClick={() => history.push("/app/profile")}
        >
          {userData.displayName
            .split(" ")
            .reduce((acc, name) => acc + name.charAt(0).toUpperCase(), "")}
        </Avatar>
      </div>
      <div
        className="stps-title-label"
        onClick={() => history.push("/app/profile")}
        style={{ cursor: "pointer" }}
      >
        {userData.displayName}
      </div>
      <div className="stps-amount mt-4" style={{ fontSize: 22 }}>
        Your Current Stage
      </div>
      <div
        className=" d-flex flex-column align-items-center mt-4 pt-2 pb-2 pl-3 pr-3 rounded"
        style={{ border: "1px solid #E5E5E5", cursor: "pointer" }}
        onClick={() => history.push("/app/scoring")}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: "18px",
          }}
        >
          <span
            style={{
              fontSize: "55px",
            }}
          >
            {userData.stage}
          </span>
        </div>
        <div
          className="stps-title-label"
          style={{ fontSize: "14px", color: "#009E11" }}
        >
          Good
        </div>
      </div>
      <div style={{ marginTop: "30px", width: "90%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div style={{ fontSize: "0.8rem", color: "gray" }}>
              Expected property date
            </div>
            <div style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
              {moment(userData.expectedPropertyDate).format("Do MMM YYYY")}
            </div>
          </div>
          <House />
        </div>
        <Progress
          strokeColor="#ef6225"
          trailColor="#c4c4c4"
          showInfo={false}
          percent={percentage}
          style={{ height: 5 }}
        />
      </div>
      <Button
        type="primary"
        style={{
          margin: "20px 10px",
          height: 60,
          display: "flex",
          alignItems: "center",
          width: "90%",
        }}
        onClick={() => history.push("/user/expedite")}
      >
        <span style={{ position: "absolute", left: 10 }}>
          Get to your home sooner{" "}
          <ArrowRightOutlined
            style={{
              color: "white",
              borderRadius: "50%",
              backgroundColor: "#fc9751",
              padding: 5,
            }}
          />
        </span>
      </Button>
    </div>
  );
};

export default memo(SideNavAvatar);

// const mapStateToProps = ({ client }) => {
//     const { loading: clientLoading, client: clientData } = client

//     return {
//       clientLoading,
//       clientData
//     };
//   };
//   export default connect(mapStateToProps, null)(memo(SideNavAvatar));
