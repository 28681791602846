import { all, put, fork, call, takeEvery } from 'redux-saga/effects';
import ScoreCardService from 'services/ScoreCards';
import {message} from 'antd'


  export function* getScoreCardSaga() {
    yield takeEvery('START_GET_SCORE_CARDS', function* () {
          try {
              const response = yield call(ScoreCardService.getScoreCards);
              console.log("🚀 ~ file: ResearchMetrix.js ~ line 11 ~ yieldtakeLeading ~ response", response)
              
              yield put({type: 'DONE_GET_SCORE_CARDS',data: response.data})
          } catch (error) {
            console.log('Error Message', error.message);
            yield put({type: 'STOP_LOADING'})
            message.error(error.message);
          }
      });
  }


  export default function* rootSaga() {
    yield all([
        fork(getScoreCardSaga)
    ]);
  }