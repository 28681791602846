export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_COUNTRY = 'GB'
export const organizationTypes = [
  {
    value: "Small",
    label: "content.organizations.type.small",
  },
  {
    value: "Medium",
    label: "content.organizations.type.medium",
  },
  {
    value: "Large",
    label: "content.organizations.type.large",
  },
  {
    value: "Enterprise",
    label: "content.organizations.type.enterprise",
  },
]
export const sourceTypes = [
  {
    value: 'Web',
    label: 'content.web'
  },
  {
    value: 'Mobile',
    label: 'content.mobile'
  }
]
export const staffUserRole = [
  {
    value: '1',
    label: 'content.role.superAdmin'
  },
  {
    value: '2',
    label: 'content.role.user'
  }
]