import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";

export default () => {
  axios.defaults.baseURL = API_BASE_URL;
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "AUTH_TOKEN"
      )}`;
      return config;
    },
    (err) => Promise.reject(err)
  );
};
