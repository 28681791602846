import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Avatar, Upload, message, Tooltip } from "antd";
import IntlMessage from "components/util-components/IntlMessage";
import { connect } from "react-redux";
import {
  EditFilled,
  CaretDownFilled,
  LogoutOutlined,
  UserOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import IcoMoonIcon from "components/util-components/IcoMoonIcon";
import FormattingService from "services/FormattingService";
import ImgCrop from "antd-img-crop";
import { env } from "configs/EnvironmentConfig";
import Axios from "axios";
import ErrorHandlingService from "services/ErrorHandlingService";

const menuItem = [
  {
    title: "Update my Profile",
    icon: EditFilled,
    path: "/",
  },
];
//Original avatar background color = #3C516D

export const NavProfile = ({ signOut }) => {
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [key, setKey] = useState(null);
  // const profileImg = "/img/avatars/default-user.jpg";
  const logOut = () => {
    localStorage.clear();
    setUserName("");
    setFile(null);
    setKey(null);
  };
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const payload = FormattingService.parseJwt();

    // setUserName(payload.given_name + " " + payload.family_name);

    if (payload.userId) {
      Axios.get(`/users/${payload.userId}/profile`)
        .then((res) => {
          console.log("🎉🎉🎉🎉", res.data);
          setUserName(res.data.data.displayName);
          setKey(res.data.data.key);
        })
        .catch((error) => {
          ErrorHandlingService(error?.response.status);
        });
    }

    const onScroll = () => setVisible(false);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [setUserName]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const beforeUploadHandler = (file) => {
    // console.log(file);
    if (file?.size > 10 * 1024 * 1024) return false;
    return true;
  };

  const fileUploadHandler = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      Axios.post(`users/profilepicture/upload`, formData, config)
        .then((res) => {
          setKey(res.data.data);
          message.success(`Profile picture uploaded successfully.`);
        })
        .catch((error) => {
          ErrorHandlingService(error?.response.status);
        });
    } else if (status === "error") {
      message.error(`Profile picture upload failed.`);
    }
  };

  const getUploadedFile = (options) => {
    const { file, onSuccess } = options;
    console.log(file);
    setFile(file);
    onSuccess();
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {/* <Avatar size={45} src={profileImg} /> */}
          <Tooltip placement="left" title="Click to change!" zIndex={10000}>
            <div style={{ position: "relative" }}>
              <div
                className="rounded-circle"
                style={{ backgroundColor: "#3C516D", cursor: "pointer" }}
              >
                <ImgCrop
                  shape="round"
                  rotate
                  quality={1}
                  modalOk="Upload"
                  modalTitle="Crop Image"
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    name="file"
                    showUploadList={false}
                    beforeUpload={beforeUploadHandler}
                    onChange={fileUploadHandler}
                    customRequest={getUploadedFile}
                  >
                    <Avatar
                      size={48}
                      src={
                        key
                          ? `${
                              env.API_ENDPOINT_URL
                            }documents/files/${encodeURIComponent(key)}`
                          : ""
                      }
                      style={{ color: "white", backgroundColor: "#ef6225" }}
                    >
                      {userName
                        .split(" ")
                        .reduce(
                          (acc, name) => acc + name.charAt(0).toUpperCase(),
                          ""
                        )}
                    </Avatar>

                    {/* <IcoMoonIcon
                    icon="User"
                    size={20}
                    className="text-white"
                    style={{ verticalAlign: "sub" }}
                  /> */}
                  </Upload>
                </ImgCrop>
              </div>
            </div>
          </Tooltip>
          <div className="pl-3">
            <h4 className="mb-0 font-size-md">{userName}</h4>
            <span className="text-muted font-size-sm">
              {/* Last logged today at 16.21 */}
            </span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })} */}
          <Menu.Item key={menuItem.legth + 1} onClick={(e) => logOut()}>
            <a href="/auth/login">
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">
                <IntlMessage id={"auth.logout"} />
              </span>
            </a>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown
      placement="bottomRight"
      overlay={profileMenu}
      trigger={["click"]}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      overlayStyle={{ position: "fixed" }}
    >
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          {/* <Avatar src={profileImg} /> */}
          <div
            className=" d-inline rounded-circle"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <Avatar
              size={48}
              src={
                key
                  ? `${
                      env.API_ENDPOINT_URL
                    }documents/files/${encodeURIComponent(key)}`
                  : ""
              }
              style={{ color: "white", backgroundColor: "#ef6225" }}
            >
              {userName
                .split(" ")
                .reduce((acc, name) => acc + name.charAt(0).toUpperCase(), "")}
            </Avatar>
          </div>
          <CaretDownFilled className="pl-2" />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
