import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Main from "./Main";
import Bank from "./Bank";
import ResearchMetrix from "./ResearchMetrix";
import User from "./User";
import Application from "./Application";
import ScoreCards from "./ScoreCards";
import Payment from "./Payment";
import MatrixLogin from "./ReMetrixLogin";
import Notification from './Notification';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  main: Main,
  bank: Bank,
  researchMetrix: ResearchMetrix,
  user: User,
  application: Application,
  scoreCard: ScoreCards,
  payment: Payment,
  matrixLogin: MatrixLogin,
  notification: Notification,
});

export default reducers;
