import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import DocumentLayout from "layouts/document-layout";
import ClientLayout from "layouts/client-layout";
import ResearchMatrixLayout from "layouts/research-matrix-layout";
import ResearchMatrixLayoutV2 from "layouts/v2/research-matrix-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  USER_PREFIX_PATH,
  CLIENT_PREFIX_PATH,
  CLIENTFF_PREFIX_PATH,
  CLIENT_v2_PREFIX_PATH,
  DOCUMENT_REQ_PREFIX_PATH,
} from "configs/AppConfig";
import FactFindingLayout from "layouts/fact-finding-layout";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          // <Redirect to="/client/research-matrix" />
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location } = props;
  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path="/">
            <Redirect to={`${APP_PREFIX_PATH}/clientdashboard`} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout />
          </Route>
          <Route path={CLIENT_v2_PREFIX_PATH}>
            <ResearchMatrixLayout />
          </Route>
          <Route path={CLIENT_PREFIX_PATH}>
            <ResearchMatrixLayoutV2 />
          </Route>
          <Route path={CLIENTFF_PREFIX_PATH}>
            <FactFindingLayout />
          </Route>
          <Route path={DOCUMENT_REQ_PREFIX_PATH}>
            <DocumentLayout location={location} />
          </Route>
          <Route path={USER_PREFIX_PATH}>
            <ClientLayout location={location} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token } = auth;
  return { locale, token };
};

export default withRouter(connect(mapStateToProps)(Views));
