import { Button, Col, Layout, Menu, Row, Form, message, Modal } from "antd";

import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CheckCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { Logo } from "components/layout-components/Logo";
import moment from "moment";

import QuestionRenderer from "../components/QuestionRenderer";
import axios from "axios";
import ErrorHandlingService from "services/ErrorHandlingService";
const { Sider, Content } = Layout;

const FactFindingForm = () => {
  const [form] = Form.useForm();
  const { confirm, warning } = Modal;
  let history = useHistory();

  const [questions, setSection] = useState([]);
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState();
  const [defaultAnswers, setDefaultAnswers] = useState();
  const [slicePoints, setSlicePoints] = useState({
    startPoint: "0",
    endPoint: "5",
  });
  const [prefilledValues, setPreFilledValues] = useState(null);

  useEffect(() => {
    async function fetchQuestion() {
      await axios
        .get("/answers/preFilled")
        .then((res) => {
          setPreFilledValues(res.data);
        })
        .catch((error) => {
          ErrorHandlingService(error?.response.status);
        });

      await axios
        .get("/ffQuestions/section")
        .then((res) => {
          setSection(res.data);
        })
        .catch((error) => {
          ErrorHandlingService(error?.response.status);
        });
    }
    fetchQuestion();
  }, []);

  const handleNavigation = (values, action) => {
    let activeStep = steps;
    if (action === "next") {
      if ((activeStep + 1) % 5 === 0) {
        setSlicePoints({ startPoint: steps + 1, endPoint: steps + 6 });
      }
      setAnswers({ ...answers, ...values });

      setSteps(activeStep + 1);
    } else if (action === "pre") {
      if ((activeStep + 1) % 5 === 1) {
        setSlicePoints({ startPoint: steps - 5, endPoint: steps });
      }
      setSteps(activeStep - 1);
    }
  };

  const handleSaveAndExit = () => {
    setLoading(true);
    confirm({
      title: "Are You Sure Want To Save and Exit ?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        const currentAnswers = form.getFieldsValue();

        const saveAnswers = {
          ...defaultAnswers,
          ...answers,
          ...currentAnswers,
        };

        for (const answer in saveAnswers) {
          if (saveAnswers[answer]?._isAMomentObject) {
            saveAnswers[answer] = saveAnswers[answer].format("YYYY-MM-DD");
          }
        }

        console.log("🎉🎉🎉", saveAnswers);

        axios
          .post(`/ffAnswers`, saveAnswers)
          .then((res) => {
            message.success("Save Data Successfully");
            history.push("/app/factfinding");
            setLoading(false);
          })
          .catch((error) => {});
      },
      onCancel() {
        setLoading(false);
      },
    });
  };

  const handleSideBarMenuItems = () => {
    return (
      <Menu
        mode="inline"
        style={{
          background: "#FB8C26",
          height: "100vh",
          borderRight: 0,
          paddingTop: "200px",
          position: "fixed",
          width: "300px",
        }}
      >
        {questions
          .slice(slicePoints.startPoint, slicePoints.endPoint)
          .map((sectionTitle, i) => (
            <Menu.Item
              className="sidenavitem"
              style={{ color: "#FFFFFF", height: "60px" }}
              key={i}
            >
              <div>
                <Row>
                  <Col span={4}>
                    <CheckOutlined
                      style={{
                        marginTop: "20px",
                        padding: "0px",
                        border: "5px solid #000000",
                        borderRadius: "50px",
                        backgroundColor: "#000000",
                        opacity: "0.2",
                        color: "#FFFFFF",
                      }}
                    />
                  </Col>
                  <Col span={20}>
                    <Row style={{ opacity: "0.5" }}>
                      {sectionTitle.sectionId > 9
                        ? sectionTitle.sectionId
                        : `0${sectionTitle.sectionId}`}
                    </Row>
                    <Row style={{ marginTop: "-20px", opacity: "0.5" }}>
                      {sectionTitle.sectionName}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Menu.Item>
          ))}
      </Menu>
    );
  };
  // const onFinish = (values) => {
  //   console.log("Finish:", values);
  // };

  return (
    <div>
      {/* <IntlProvider> */}
      <Layout>
        <Sider className="site-layout-background" width={300} height={1000}>
          {handleSideBarMenuItems()}
        </Sider>
        <Content>
          <Row>
            <Col span={8}></Col>
            <Col span={8} offset={8}>
              <Button
                size="small"
                style={{
                  width: "40%",
                  float: "right",
                  marginTop: "26px",
                  marginRight: "50px",
                  borderColor: "#EF6225",
                  color: "#EF6225",
                  borderRadius: "4px",
                }}
                htmlType="submit"
                block
                onClick={() => handleSaveAndExit()}
              >
                Save and Exit
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                className="my-4"
                style={{
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  fontSize: "40px",
                  paddingLeft: "50px",
                }}
              >
                {questions[steps]?.sectionName}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div
                className="container d-flex flex-column justify-content-center h-100"
                style={{ paddingLeft: "50px" }}
              >
                <Form.Provider>
                  <Form
                    name="fact-finding"
                    initialValues={{ expenses_list: [undefined] }}
                    onFinish={(value) => handleNavigation(value, "next")}
                    form={form}
                    // onFinish={onFinish}
                  >
                    <Row>
                      <Col span={24}>
                        {/* <Card style={{ backgroundColor: "transparent", border: "0px" }}> */}
                        <div className="my-4">
                          {questions[steps] !== undefined ? (
                            <QuestionRenderer
                              section={questions[steps]}
                              prefilledVals={prefilledValues}
                            ></QuestionRenderer>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <Form.Item>
                          <Row gutter={15} style={{ paddingLeft: "0px" }}>
                            <Col span={12}>
                              {steps === 0 ? null : (
                                <Button
                                  size="small"
                                  block
                                  onClick={(value) =>
                                    handleNavigation(value, "pre")
                                  }
                                >
                                  Previous
                                </Button>
                              )}
                            </Col>
                            <Col span={12}>
                              {steps === questions.length - 1 ? null : (
                                <Button
                                  size="small"
                                  type="primary"
                                  htmlType="submit"
                                  block
                                >
                                  Next
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={8} offset={8}>
                        <div className="text-center">
                          <img
                            className="img-fluid"
                            src="/img/background-rtb.png"
                            width="50%"
                            alt=""
                            style={{
                              position: "fixed",
                              right: "-100px",
                              bottom: "0px",
                            }}
                          />
                          {/* <p>Don't have an account yet? <a href="/auth/register-1">Sign Up</a></p> */}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Form.Provider>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
      {/* </IntlProvider> */}
    </div>
  );
};

export default FactFindingForm;
