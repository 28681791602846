import axios from "axios";

const bankService = {};

bankService.getToken = async () => {
  return await axios.get("/banks/token", {});
};

bankService.createUser = async (data) => {
  return await axios.post("/banks/users", data);
};

bankService.getInstitutions = async () => {
  return await axios.get("/banks/institutions", {});
};

bankService.getInstitutions = async () => {
  return await axios.get("/banks/institutions", {});
};

bankService.connectInstitution = async (data, userId) => {
  return await axios.post(`/banks/users/${userId}/connections`, data);
};

bankService.verifyConnection = async (jobId) => {
  return await axios.get(`/banks/jobs/${jobId}`);
};

bankService.createUserSignature = async (data) => {
  return await axios.post("/users/signature", data);
};

bankService.signatureSubmit = async (data) => {
  return await axios.post("/users/signature/submit", data);
};

bankService.createCustomer = async (data) => {
  return await axios.post("/stripes/createCustomer", data);
};

bankService.getHousingEligibility = async (userId) => {
  return await axios.get(`/calculation/housing/${userId}`, {});
};

bankService.getApartmentEligibility = async (userId) => {
  return await axios.get(`/calculation/apartment/${userId}`, {});
};

bankService.getInvestmentEligibility = async (userId) => {
  return await axios.get(`/calculation/investment/${userId}`, {});
};

bankService.paymentCalculation = async (userId) => {
  return await axios.get(`/calculation/resultPagePayment/${userId}`, {});
  //return await axios.get(`https://e640031e-faa7-4319-88e4-94db48905a36.mock.pstmn.io/calculation/repayment/`, {})
};

export default bankService;
