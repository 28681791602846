import {
  Col,
  Input,
  Row,
  Select,
  Form,
  Radio,
  Button,
  DatePicker,
  Checkbox,
} from "antd";
import React, { Fragment, useState } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import TextInput from "./TextInput";

import EditableRow from "./EditableRow";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRowAssests from "./EditableRowAssests";
import ReadOnlyRowAssests from "./ReadOnlyRowAssests";

const { Option } = Select;

const data1 = [
  {
    id: 1,
    description: "Credit Card",
    owner: "James",
    balanceOwning: "$600",
    lender: "ANZ",
    rate: "12.99%",
    monthlyRepay: "50%",
  },
];

const data2 = [
  {
    idAsst: 1,
    descriptionAsst: "Home",
    ownerAsst: "James",
    currentValueAsst: "$600",
    typeAsst: "",
  },
];

const QuestionRenderer = (props) => {
  const { section, prefilledVals } = props;

  const [dynamicFIelds, setDynamicFields] = useState([
    { depname: "", depdob: "", depage: "" },
  ]);

  const [insDynamicFIelds, setInsDynamicFields] = useState([
    { insOwner: "", insName: "", insType: "", insAmount: "" },
  ]);

  const filterByType = (answObj, type) => {
    let filteredArrya = [];
    {
      answObj.map((item) =>
        item.type == type ? filteredArrya.push(item) : null
      );
    }
    return filteredArrya;
  };

  const renderFields = (queId, fieldType, fieldValues, style) => {
    switch (fieldType) {
      case "select":
      case "select-event": {
        return (
          <Form.Item
            style={{ marginBottom: "10px", width: "70%" }}
            key={queId}
            className={style}
            name={fieldValues[0].fieldId}
          >
            <Select size="small" style={{ fontSize: "15px" }}>
              {fieldValues?.map((answers) => (
                <Option key={answers.answerId} value={answers.answer}>
                  {answers.answer}
                </Option>
              ))}
            </Select>
          </Form.Item>

          // <SelectInputff
          //   key={`selectInput-${queId}`}
          //   name={fieldValues[0].name}
          //   placeholder={"select an option"}
          //   id={queId}
          //   answer=""
          //   isValidationActive={false}
          //   options={fieldValues}
          //   onChangeEvent={(e) => handleSelectOnChangeEvent(e)}
          // />
        );
      }
      case "input": {
        return (
          <div>
            {fieldValues?.map((answer, i) => (
              // <Form.Item
              //   style={{ marginBottom: "10px" }}
              //   key={`${queId}-${i}`}
              //   name={answer.name}
              // >
              <TextInput
                key={`textinput-${queId}-${i}`}
                name={answer.fieldId}
                id={`input-${answer.answerId}`}
                answer={
                  answer.name === "firstName"
                    ? prefilledVals?.prefilledobj.firstName
                    : answer.name === "surname"
                    ? prefilledVals?.prefilledobj.surName
                    : answer.name === "mobilePhone"
                    ? prefilledVals?.prefilledobj.mobile
                    : answer.answer
                }
                placeholder={answer.placeholder}
                isValidationActive={false}
                idDisable={
                  answer.name === "firstName"
                    ? true
                    : answer.name === "surname"
                    ? true
                    : answer.name === "mobilePhone"
                    ? true
                    : false
                }
              />
              // </Form.Item>
              // <Input
              //   className={style}
              //   placeholder=""
              //   size="small"
              //   key={answer.answerId}
              //   name={answer.name}
              //   id={answer.answerId}
              //   onChange={handleAddFormChange}
              // ></Input>
            ))}
          </div>
        );
      }
      case "input-event": {
        return (
          <Form.Item style={{ marginBottom: "10px" }} key={queId}>
            {fieldValues?.map((answer, i) => (
              // <TextInput
              //   key={`textinput-${queId}-${i}`}
              //   name={answer.name}
              //   id={`input-${answer.answerId}`}
              //   answer={answer.answer}
              //   placeholder={answer.placeholder}
              //   isValidationActive={false}
              //   handleChange={(e) => handleAddFormChange(e)}
              // />
              <Input
                className={style}
                placeholder=""
                size="small"
                key={answer.answerId}
                name={answer.name}
                id={answer.answerId}
                onChange={handleAddFormChange}
              ></Input>
            ))}
          </Form.Item>
        );
      }
      case "datepicker": {
        return (
          <div>
            {fieldValues.map((answer, i) => (
              <Form.Item
                style={{ marginBottom: "10px" }}
                key={`dp-${queId}-${i}`}
                name={fieldValues[0].fieldId}
              >
                <DatePicker
                  id={`input-${answer.answerId}`}
                  size="middle"
                  style={{
                    width: "70%",
                    border: "none",
                  }}
                />
              </Form.Item>
            ))}
          </div>
        );

        // <Form.Item style={{ marginBottom: "10px" }} key={queId}>
        //   {answer[i].answer?.map((answerSub, j) => (
        //     <Input
        //       id={`inputlistid-${answer[i].AnswerId}-${j}`}
        //       name={answer.AnswerId}
        //       className={style}
        //       placeholder=""
        //       size="small"
        //       key={`inputlist-${answer.AnswerId}-${i}`}
        //       style={{ width: "100%" }}
        //     ></Input>
        //   ))}
        // </Form.Item>
        // );
      }
      case "radio": {
        return (
          <Form.Item
            style={{ marginBottom: "10px" }}
            key={queId}
            name={fieldValues[0].fieldId}
          >
            <Radio.Group>
              {fieldValues?.map((answer) => (
                <Radio
                  value={answer.answer}
                  name={answer.fieldId}
                  id={answer.answerId}
                >
                  {answer.answer}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      }
      case "radio-input": {
        return (
          <Form.Item
            style={{ marginBottom: "10px" }}
            key={queId}
            name={fieldValues[0].fieldId}
          >
            <Radio.Group className={style}>
              {filterByType(fieldValues, "radio")?.map((answer) => (
                <Radio
                  value={answer.answer}
                  name={answer.fieldId}
                  id={answer.answerId}
                >
                  {answer.answer}
                </Radio>
              ))}
            </Radio.Group>
            <div
              style={{
                paddingTop: "0px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              Amounts
            </div>
            {filterByType(fieldValues, "input")?.map((answer, i) => (
              <TextInput
                key={`textinput-${queId}-${i}`}
                name={answer.fieldId}
                answer={answer.answer}
                placeholder={answer.label}
                id={`${queId}-${i}`}
                isValidationActive={false}
              />
              //   <Input
              //     className="factfindingtextinput"
              //     placeholder=""
              //     size="small"
              //     key={answer.answerId}
              //     name={answer.name}
              //     id={answer.answerId}
              //   ></Input>
            ))}
          </Form.Item>
        );
      }
      case "select-input": {
        return (
          <Form.Item
            style={{ marginBottom: "10px" }}
            key={queId}
            className={style}
            name={fieldValues[0].fieldId}
          >
            <div
              style={{
                paddingTop: "0px",
                fontWeight: "600",
              }}
            >
              Time period
            </div>
            <Select size="small" style={{ width: "70%", fontSize: "15px" }}>
              {filterByType(fieldValues, "select")?.map((answer) => (
                <Option key={answer.answerId}>{answer.answer}</Option>
              ))}
            </Select>
            <div
              style={{
                paddingTop: "0px",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              Amounts
            </div>
            {filterByType(fieldValues, "input")?.map((answer, i) => (
              <TextInput
                key={`textinput-${queId}-${i}`}
                name={answer.fieldId}
                answer={answer.answer}
                placeholder={answer.label}
                id={`${queId}-${i}`}
                isValidationActive={false}
              />
              //   <Input
              //     className="factfindingtextinput"
              //     placeholder=""
              //     size="small"
              //     key={answer.answerId}
              //     name={answer.name}
              //     id={answer.answerId}
              //   ></Input>
            ))}
          </Form.Item>
        );
      }
      case "check": {
        return (
          <Form.Item style={{ marginBottom: "10px" }} key={queId}>
            {/* <Checkbox.Group className={style}> */}
            {fieldValues?.map((answer) => (
              <Checkbox
                value={answer.answer}
                name={answer.fieldId}
                id={answer.answerId}
              >
                {answer.answer}
              </Checkbox>
            ))}
            {/* </Checkbox.Group> */}
          </Form.Item>
        );
      }
      default:
        return null;
    }
  };

  //===============Dynamic Table for additional liabilities======================
  let fieldNames = {};

  // let fNames = [
  //   "id",
  //   "description",
  //   "owner",
  //   "balanceOwning",
  //   "lender",
  //   "rate",
  //   "monthlyRepay",
  // ];

  // let fNames = [
  //   { name: "id", displayName: "id", size: "", isVisible: true },
  //   {
  //     name: "description",
  //     displayName: "Description",
  //     size: "5",
  //     isVisible: false,
  //   },
  //   { name: "owner", displayName: "Owner", size: "4", isVisible: false },
  //   {
  //     name: "balanceOwning",
  //     displayName: "Balance Owning",
  //     size: "3",
  //     isVisible: false,
  //   },
  //   { name: "lender", displayName: "Lender", size: "3", isVisible: false },
  //   { name: "rate", displayName: "Rate", size: "2", isVisible: false },
  //   {
  //     name: "monthlyRepay",
  //     displayName: "Monthly repayment",
  //     size: "4",
  //     isVisible: false,
  //   },
  // ];

  let data = [];
  if (section.sectionName === "Assets and other Income Details") {
    data = data2;
  } else {
    data = data1;
  }
  const [contacts, setContacts] = useState(data);

  if (section.sectionName === "Assets and other Income Details") {
    fieldNames = {
      idAsst: 1,
      descriptionAsst: "",
      ownerAsst: "",
      currentValueAsst: "",
      typeAsst: "",
    };
  } else if (section.sectionName === "Additional Liabilities") {
    fieldNames = {
      id: 1,
      description: "Credit Card",
      owner: "",
      balanceOwning: "",
      lender: "",
      rate: "",
      monthlyRepay: "",
    };
  }

  // fNames.map((v) => (fieldNames = { ...fieldNames, [v.name]: "" }));
  const [addFormData, setAddFormData] = useState({
    // description: "",
    // owner: "",
    // balanceOwning: "",
    // lender: "",
    // rate: "",
    // monthlyRepay: "",
    fieldNames,
  });

  const [editFormData, setEditFormData] = useState({
    // description: "",
    // owner: "",
    // balanceOwning: "",
    // lender: "",
    // rate: "",
    // monthlyRepay: "",
    fieldNames,
  });

  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleSelectOnChangeEvent = (event) => {
    // event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    // event.preventDefault();
    let newContact = {};
    if (section.sectionName === "Assets and other Income Details") {
      newContact = {
        idAsst: contacts[contacts.length - 1].idAsst + 1,
        descriptionAsst: addFormData.descriptionAsst,
        ownerAsst: addFormData.ownerAsst,
        currentValueAsst: addFormData.currentValueAsst,
        typeAsst: addFormData.typeAsst,
      };
    } else {
      newContact = {
        id: contacts[contacts.length - 1]?.id + 1,
        description: addFormData.description,
        owner: addFormData.owner,
        balanceOwning: addFormData.balanceOwning,
        lender: addFormData.lender,
        rate: addFormData.rate,
        monthlyRepay: addFormData.monthlyRepay,
      };
    }

    // fNames.map(
    //   (a) => (newContact = { ...newContact, [a.name]: addFormData[a.name] })
    // );
    const newContacts = [...contacts, newContact];
    setContacts(newContacts);
  };

  const handleEditFormSubmit = (event) => {
    // event.preventDefault();
    let editedContact = {};
    if (section.sectionName === "Assets and other Income Details") {
      editedContact = {
        idAsst: editContactId,
        descriptionAsst: editFormData.descriptionAsst,
        ownerAsst: editFormData.ownerAsst,
        currentValueAsst: editFormData.currentValueAsst,
        typeAsst: editFormData.typeAsst,
      };
    } else {
      editedContact = {
        id: editContactId,
        description: editFormData.description,
        owner: editFormData.owner,
        balanceOwning: editFormData.balanceOwning,
        lender: editFormData.lender,
        rate: editFormData.rate,
        monthlyRepay: editFormData.monthlyRepay,
      };
    }

    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === editContactId);

    newContacts[index] = editedContact;

    setContacts(newContacts);
    setEditContactId(null);
  };

  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);
    let formValues = {};
    if (section.sectionName === "Assets and other Income Details") {
      formValues = {
        descriptionAsst: contact.descriptionAsst,
        ownerAssr: contact.ownerAsst,
        currentValueAsst: contact.currentValueAsst,
        typeAsst: contact.typeAsst,
      };
    } else {
      formValues = {
        description: contact.description,
        owner: contact.owner,
        balanceOwning: contact.balanceOwning,
        lender: contact.lender,
        rate: contact.rate,
        monthlyRepay: contact.monthlyRepay,
      };
    }

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];

    const index = contacts.findIndex((contact) => contact.id === contactId);

    newContacts.splice(index, 1);

    setContacts(newContacts);
  };
  //===============Dynamic Table for additional liabilities======================

  switch (section.sectionName) {
    case "Personal Details":
    case "Contact Details":
    case "Employment Details":
    case "Declaration and Consents": {
      return (
        <Row justify="center">
          {section.questions?.map((q, i) => (
            <Col span={24} key={i}>
              <div className="factfindlabel">{q.question}</div>
              <div className="factfindingtextinputdiv">
                {/* <Form.Item
                  name="title" 
                  style={{ marginBottom: "10px" }}
                  key={q.QuestionId}
                  className={q.class1}
                > */}
                {renderFields(q.questionId, q.answerType, q.answers, q.class1)}
                {/* </Form.Item> */}
              </div>
            </Col>
          ))}
        </Row>
      );
    }
    case "Dependents": {
      // setDynamicFields(section.questions[0].answers[0].answer);
      const handleAddNewRow = () => {
        setDynamicFields([
          ...dynamicFIelds,
          { depname: "", depdob: "", depage: "" },
        ]);
      };

      const handleRemoveRow = (index) => {
        const valuesToBeRem = [...dynamicFIelds];
        valuesToBeRem.splice(index, 1);
        setDynamicFields(valuesToBeRem);
      };

      const handleChangeInput = (index, event) => {
        const dynamicFIeldVals = [...dynamicFIelds];
        dynamicFIeldVals[index][event.target.name] = event.target.value;
        setDynamicFields(dynamicFIeldVals);
      };

      return (
        <div>
          <Row gutter={15}>
            <Col span={7}>
              <div className="factfindlabel">Name</div>
            </Col>
            <Col span={7}>
              <div className="factfindlabel">Date of birth</div>
            </Col>
            <Col span={7}>
              <div className="factfindlabel">Age until dependency stops</div>
            </Col>
          </Row>
          {/* <Row justify="center"> */}
          {dynamicFIelds?.map((q, i) => (
            <Form.Item
              style={{ marginBottom: "10px" }}
              key={`key-${section.questions[0].answers[0].AnswerId}-${i}`}
            >
              <Row gutter={15}>
                <Col span={7}>
                  <Form.Item name={`${i}-depname`}>
                    <Input
                      name="depname"
                      id={`id-${section.questions[0].answers[0].AnswerId}-${i}`}
                      className="factfindingtextinput"
                      value={q.name}
                      placeholder=""
                      onChange={(event) => handleChangeInput(i, event)}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={`${i}-depdob`}>
                    <DatePicker
                      name="depdob"
                      id={`id-${section.questions[0].answers[0].AnswerId}-${i}`}
                      size="middle"
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={`${i}-depage`}>
                    <Input
                      name="depage"
                      id={`id-${section.questions[0].answers[0].AnswerId}-${i}`}
                      className="factfindingtextinput"
                      value={q.name}
                      placeholder=""
                      onChange={(event) => handleChangeInput(i, event)}
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Button
                    size="small"
                    style={{
                      float: "right",
                      borderColor: "#EF6225",
                      color: "#EF6225",
                      borderRadius: "4px",
                      margin: "0px",
                    }}
                    onClick={handleAddNewRow}
                  >
                    <PlusOutlined />
                  </Button>
                  {i !== 0 ? (
                    <Button
                      size="small"
                      style={{
                        float: "right",
                        borderColor: "#EF6225",
                        color: "#EF6225",
                        borderRadius: "4px",
                        margin: "0px",
                      }}
                      onClick={() => handleRemoveRow(i)}
                    >
                      <MinusOutlined />
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Form.Item>
          ))}
          {/* </Row> */}
        </div>
      );
    }
    case "Citizenship and Other Details":
    case "Estate planning": {
      return (
        <Row justify="center">
          {section.questions?.map((q) => (
            <Col span={24}>
              <Row style={{ marginBottom: "40px" }}>
                <Col span={16}>
                  <div style={{ paddingTop: "0px" }}>{q.question}</div>
                </Col>
                <Col span={8}>
                  {renderFields(
                    q.questionId,
                    q.answerType,
                    q.answers,
                    q.class1
                  )}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      );
    }
    case "Expenditure Details": {
      return (
        <Row justify="center">
          <Col span={24}>
            <Row>
              <Col span={14}>
                <div
                  style={{
                    paddingTop: "0px",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}
                >
                  Decription
                </div>
              </Col>
              <Col span={5}>
                <div
                  style={{
                    paddingTop: "0px",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}
                >
                  Frequency
                </div>
              </Col>
              <Col span={5}>
                <div
                  style={{
                    paddingTop: "0px",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}
                >
                  Amount
                </div>
              </Col>
            </Row>

            {section.questions?.map((q) => (
              <Row>
                <Col span={14}>
                  <div style={{ paddingTop: "0px" }}>{q.question}</div>
                </Col>
                <Col span={5}>
                  {renderFields(
                    q.questionId,
                    "select",
                    q.answers
                      .filter((items) => items.type === "select")
                      .map((types) => types),
                    q.class1
                  )}
                </Col>
                <Col span={5}>
                  {renderFields(
                    q.questionId,
                    "input",
                    q.answers
                      .filter((items) => items.type === "input")
                      .map((types) => types),
                    "factfindingtextinput"
                  )}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      );
    }
    case "Savings and income details": {
      return (
        <Row justify="center">
          {section.questions?.map((q) => (
            <Col span={24}>
              <Row style={{ marginBottom: "40px" }}>
                <Col span={16}>
                  <div style={{ paddingTop: "0px" }}>{q.question}</div>
                </Col>
                <Col span={8}>
                  {renderFields(
                    q.questionId,
                    q.answerType,
                    q.answers,
                    q.class1
                  )}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      );
    }
    case "Assets and other Income Details": {
      return (
        <div>
          <Form>
            <Row justify="center" style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <Row>
                  {section.questions?.map((q) => (
                    <Col span={12}>
                      <div className="factfindlabel">{q.question}</div>
                      <div className="factfindingtextinputdiv">
                        {/* <Form.Item
                        name="title" 
                        style={{ marginBottom: "10px" }}
                        key={q.QuestionId}
                        className={q.class1}
                      > */}

                        {renderFields(
                          q.questionId,
                          q.answerType,
                          q.answers,
                          q.class1
                        )}
                        {/* </Form.Item> */}
                      </div>
                    </Col>
                  ))}
                </Row>
                {/* {<ListInputFields fieldValues={fields} />} */}
                <Row>
                  <Col span={24}>
                    <Row gutter={15} style={{ paddingLeft: "0px" }}>
                      <Col span={3}>
                        <Button
                          size="small"
                          style={{
                            float: "left",
                            marginTop: "26px",
                            marginRight: "50px",
                            borderColor: "#A3A3A3",
                            color: "#A3A3A3",
                            borderRadius: "4px",
                          }}
                          block
                          onClick={handleAddFormSubmit}
                        >
                          Add
                        </Button>
                      </Col>
                      <Col span={4}>
                        {/* <Button
                          size="small"
                          style={{
                            float: "left",
                            marginTop: "26px",
                            marginRight: "50px",
                            borderColor: "#EF6225",
                            color: "#EF6225",
                            borderRadius: "4px",
                          }}
                          block
                        >
                          + Add another
                        </Button> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Form onFinish={handleEditFormSubmit}>
            <div>
              <Row gutter={2}>
                <Col span={5} className="factfindlabel">
                  Description
                </Col>
                <Col span={4} className="factfindlabel">
                  Owner
                </Col>
                <Col span={3} className="factfindlabel">
                  Current Value
                </Col>
                <Col span={3} className="factfindlabel">
                  Type
                </Col>
              </Row>
              {contacts?.map((contact) => (
                <Fragment>
                  {editContactId === contact.id ? (
                    <EditableRowAssests
                      editFormData={editFormData}
                      handleEditFormChange={handleEditFormChange}
                      handleCancelClick={handleCancelClick}
                    />
                  ) : (
                    <ReadOnlyRowAssests
                      contact={contact}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </Form>
        </div>
      );
    }
    case "Additional Liabilities": {
      return (
        <div>
          <Form>
            <Row justify="center" style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <Row>
                  {section.questions?.map((q) => (
                    <Col span={12}>
                      <div className="factfindlabel">{q.question}</div>
                      <div className="factfindingtextinputdiv">
                        {/* <Form.Item
                        name="title" 
                        style={{ marginBottom: "10px" }}
                        key={q.QuestionId}
                        className={q.class1}
                      > */}

                        {renderFields(
                          q.questionId,
                          q.answerType,
                          q.answers,
                          q.class1
                        )}
                        {/* </Form.Item> */}
                      </div>
                    </Col>
                  ))}
                </Row>
                {/* {<ListInputFields fieldValues={fields} />} */}
                <Row>
                  <Col span={24}>
                    <Row gutter={15} style={{ paddingLeft: "0px" }}>
                      <Col span={3}>
                        <Button
                          size="small"
                          style={{
                            float: "left",
                            marginTop: "26px",
                            marginRight: "50px",
                            borderColor: "#A3A3A3",
                            color: "#A3A3A3",
                            borderRadius: "4px",
                          }}
                          block
                          onClick={handleAddFormSubmit}
                        >
                          Add
                        </Button>
                      </Col>
                      <Col span={4}>
                        {/* <Button
                          size="small"
                          style={{
                            float: "left",
                            marginTop: "26px",
                            marginRight: "50px",
                            borderColor: "#EF6225",
                            color: "#EF6225",
                            borderRadius: "4px",
                          }}
                          block
                        >
                          + Add another
                        </Button> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Form onFinish={handleEditFormSubmit}>
            <div>
              <Row gutter={2}>
                <Col span={5} className="factfindlabel">
                  Description
                </Col>
                <Col span={4} className="factfindlabel">
                  Owner
                </Col>
                <Col span={3} className="factfindlabel">
                  Balance owning
                </Col>
                <Col span={3} className="factfindlabel">
                  Lender
                </Col>
                <Col span={2} className="factfindlabel">
                  Rate
                </Col>
                <Col span={4} className="factfindlabel">
                  Monthly repayment
                </Col>
              </Row>
              {contacts?.map((contact) => (
                <Fragment>
                  {editContactId === contact.id ? (
                    <EditableRow
                      editFormData={editFormData}
                      handleEditFormChange={handleEditFormChange}
                      handleCancelClick={handleCancelClick}
                    />
                  ) : (
                    <ReadOnlyRow
                      contact={contact}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </Form>
          {/* <DynamicListInput inputsArray={fNames} /> */}
        </div>
      );
    }
    case "Superannuation details": {
      return (
        <Row justify="center">
          {section.questions?.map((q) => (
            <Col span={6}>
              <div className="factfindlabel">{q.question}</div>
              <div className="factfindingtextinputdiv">
                {/* <Form.Item
                    name="title" 
                    style={{ marginBottom: "10px" }}
                    key={q.QuestionId}
                    className={q.class1}
                  > */}
                {renderFields(q.questionId, q.answerType, q.answers, q.class1)}
                {/* </Form.Item> */}
              </div>
            </Col>
          ))}
        </Row>
      );
    }
    case "Personal Life Insurance Details": {
      // setDynamicFields(section.questions[0].answers[0].answer);
      const handleAddNewRow = () => {
        setInsDynamicFields([
          ...insDynamicFIelds,
          { insOwner: "", insName: "", insType: "", insAmount: "" },
        ]);
      };

      const handleRemoveRow = (index) => {
        const valuesToBeRem = [...insDynamicFIelds];
        valuesToBeRem.splice(index, 1);
        setInsDynamicFields(valuesToBeRem);
      };

      const handleChangeInput = (index, event) => {
        const dynamicFIeldVals = [...insDynamicFIelds];
        dynamicFIeldVals[index][event.target.name] = event.target.value;
        setInsDynamicFields(dynamicFIeldVals);
      };

      return (
        <div>
          <Row gutter={15}>
            <Col span={5}>
              <div className="factfindlabel">Owner</div>
            </Col>
            <Col span={7}>
              <div className="factfindlabel">Insurer name</div>
            </Col>
            <Col span={5}>
              <div className="factfindlabel">Type of insurance</div>
            </Col>
            <Col span={4}>
              <div className="factfindlabel">Insurance amount</div>
            </Col>
          </Row>
          {/* <Row justify="center"> */}
          {insDynamicFIelds?.map((q, i) => (
            <Form.Item
              style={{ marginBottom: "10px" }}
              key={`key-${section.questions[0].answers[0].AnswerId}-${i}`}
            >
              <Row gutter={15}>
                <Col span={5}>
                  <Form.Item name={`${i}-insOwner`}>
                    <Input
                      name="insOwner"
                      id={`id-${section.questions[0].answers[0].AnswerId}-${i}`}
                      className="factfindingtextinput"
                      value={q.name}
                      placeholder=""
                      onChange={(event) => handleChangeInput(i, event)}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={`${i}-insName`}>
                    <Input
                      name="insName"
                      id={`id-${section.questions[0].answers[0].AnswerId}-${i}`}
                      className="factfindingtextinput"
                      value={q.name}
                      placeholder=""
                      onChange={(event) => handleChangeInput(i, event)}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={`${i}-insType`}>
                    <Select
                      size="small"
                      style={{ fontSize: "15px" }}
                      name="insType"
                    >
                      <Option>Life</Option>
                      <Option>Vehicle</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name={`${i}-insAmount`}>
                    <Input
                      name="insAmount"
                      id={`id-${section.questions[0].answers[0].AnswerId}-${i}`}
                      className="factfindingtextinput"
                      value={q.name}
                      placeholder=""
                      onChange={(event) => handleChangeInput(i, event)}
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Button
                    size="small"
                    style={{
                      float: "right",
                      borderColor: "#EF6225",
                      color: "#EF6225",
                      borderRadius: "4px",
                      margin: "0px",
                    }}
                    onClick={handleAddNewRow}
                  >
                    <PlusOutlined />
                  </Button>
                  {i !== 0 ? (
                    <Button
                      size="small"
                      style={{
                        float: "right",
                        borderColor: "#EF6225",
                        color: "#EF6225",
                        borderRadius: "4px",
                        margin: "0px",
                      }}
                      onClick={() => handleRemoveRow(i)}
                    >
                      <MinusOutlined />
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Form.Item>
          ))}
          {/* </Row> */}
        </div>
      );
    }
    case "Privacy Settings": {
      return (
        <Row justify="center">
          {section.questions?.map((q) => (
            <Col span={24}>
              <div className="">{q.question}</div>
              <div className="">
                {renderFields(q.questionId, q.answerType, q.answers, q.class1)}
              </div>
            </Col>
          ))}
        </Row>
      );
    }

    default:
      return null;
  }
};

export default QuestionRenderer;
