
  const initState = {
    notifications:[],
    loading: false
  }
    
  const notification = (state = initState, action) => {
    switch (action.type) {
      case 'DONE_GET_NEW_NOTIFICATIONS':
        return {
            ...state,
            notifications: action.data
        }
      default:
      return state;
    }
  }
  
  export default notification