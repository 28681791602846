import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { CLIENTFF_PREFIX_PATH } from "configs/AppConfig";

export const FactFindingViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${CLIENTFF_PREFIX_PATH}/fact-finding`}
          component={lazy(() => import(`./fact-finding`))}
        />
      </Switch>
    </Suspense>
  );
};

export default FactFindingViews;
