const { message } = require("antd")

const refreshUser = () => {
    localStorage.clear()
    window.location.reload()
}
 
const ErrorHandlingService = (errorCode, defaultMsg = '') => {
    errorCode === 400    
   ?message.error('Bad Request. ' + defaultMsg)
   : errorCode === 202  
   ?message.error('No Content. ' + defaultMsg)
   : errorCode === 401  
   ?refreshUser()
   : errorCode === 403  
   ?message.error('Forbidden. ' + defaultMsg)
   : errorCode === 404  
   ?message.error('Not Found. ' + defaultMsg)
   : errorCode === 405   
   ?message.error('Method Not Allowed. ' + defaultMsg)
   : errorCode === 409  
   ?message.error('Conflict. ' + defaultMsg)
   : errorCode === 411  
   ?message.error('Length Required. ' + defaultMsg)
   : errorCode === 412  
   ?message.error('Precondition Failed. ' + defaultMsg)
   : errorCode === 429   
   ?message.error('Too Many Requests. ' + defaultMsg)
   : errorCode === 500   
   ?message.error('Internal Server Error. ' + defaultMsg)
   : errorCode === 501
   ?message.error('Not Implemented. ' + defaultMsg)
   : errorCode === 503 
   ?message.error('Service Unavailable. ' + defaultMsg)
   :message.error(defaultMsg)
}
 
export default ErrorHandlingService;