import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Renting 2 Buy";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const CLIENT_PREFIX_PATH = "/client";
export const USER_PREFIX_PATH = "/user";
export const DOCUMENT_REQ_PREFIX_PATH = "/document-requirements";
export const CLIENTFF_PREFIX_PATH = "/clientff";
export const CLIENT_v2_PREFIX_PATH = "/v2/client";
export const WEB_PORTAL_URL = env.WEB_PORTAL_URL;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#ffffff",
  headerNavColor: "#ffffff", //"#282F3C",
  mobileNav: false,
};
