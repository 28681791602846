import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Bank from "./Bank";
import ResearchMetrix from "./ResearchMetrix";
import User from "./User";
import Application from "./Application";
import ScoreCard from "./ScoreCard";
import Payment from "./Payment";
import Notification from './Notification';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Bank(),
    ResearchMetrix(),
    User(),
    Application(),
    ScoreCard(),
    Payment(),
    Notification(),
  ]);
}
