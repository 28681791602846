
import { all, put, fork, call, takeEvery, takeLeading } from 'redux-saga/effects';
import { message } from 'antd'
import notificationService from 'services/NotificationService';


export function* getNewNotificationsSaga() {
    yield takeEvery('START_GET_NEW_NOTIFICATIONS', function* (input) {
        console.log("🚀 ~ file: Notification.js ~ line 9 ~ yieldtakeEvery ~ input", input)
        try {
            const { email, userId } = input.data
            const response = yield call(notificationService.getNewNotifications, email, userId);
            console.log("🚀 ~ file: Application.js ~ line 10 ~ yieldtakeEvery ~ response", response)

            yield put({ type: 'DONE_GET_NEW_NOTIFICATIONS', data: response.data })
        } catch (error) {
            console.log('Error Message', error.message);
            message.error(error.message);
        }
    });
}

export function* getClearNotificationsSaga() {
    yield takeLeading('START_CLEAR_NOTIFICATIONS', function* (input) {
        console.log("🚀 ~ file: Notification.js ~ line 24 ~ yieldtakeLeading ~ input", input)
        try {
            const { email, userId } = input.data
            const response = yield call(notificationService.clearNotifications, email, userId);
            console.log("🚀 ~ file: Application.js ~ line 10 ~ yieldtakeEvery ~ response", response)

            yield put({ type: 'START_GET_NEW_NOTIFICATIONS', data:{email,userId} })
        } catch (error) {
            console.log('Error Message', error.message);
            message.error(error.message);
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getNewNotificationsSaga),
        fork(getClearNotificationsSaga)
    ]);
}