import axios from "axios"

const notificationService = {}

notificationService.getNewNotifications = async (email, userId) =>  {
    return await axios.get(`/notifications/new?email=${email}&userId=${userId}`, {})
}

notificationService.clearNotifications = async (email, userId) =>  {
    return await axios.put(`/notifications/clear`, {email,userId})
}

export default notificationService