import axios from "axios"

const userService = {}

userService.checkUser = async (data) =>  {
  return await axios.post("/users/check", data)
}

userService.getUserStatus = async (userId) =>  {
    return await axios.get(`/users/signature?userId=${userId}`, {})
}

export default userService