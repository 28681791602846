import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
const FormItem = Form.Item;

const TextInput = (props) => {
  const { name, id, answer, placeholder, isValidationActive, idDisable } =
    props;
  let validationRules = [];

  if (isValidationActive) {
    if (name === "email") {
      validationRules = [
        {
          required: true,
          type: "email",
          message: `Please insert valid email address.`,
        },
      ];
    } else {
      validationRules = [
        { required: true, message: `Required field cannot be empty.` },
      ];
    }
  }

  return (
    <>
      <FormItem
        id={id}
        rules={validationRules}
        initialValue={answer}
        name={name}
      >
        <Input
          name={name}
          className="factfindingtextinput"
          placeholder={placeholder}
          size="small"
          disabled={idDisable}
        />
      </FormItem>
    </>
  );
};

TextInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  // value: PropTypes.string,
  placeHolder: PropTypes.string,
  isValidationActive: PropTypes.bool,
  // validateStatus: 'success' | 'warning' | 'error' | 'validating',
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      required: PropTypes.bool,
      message: PropTypes.string,
    })
  ),
  exClass: PropTypes.string,
};

TextInput.defaultProps = {
  rules: [{}],
  exClass: "",
};

export default TextInput;
