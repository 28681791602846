const initState = {
  createPaymentMethodLoading: false,
  makePaymentLoading: false,
};

const Payment = (state = initState, action) => {
  switch (action.type) {
    case "START_CREATE_PAYMENT_METHOD":
      return {
        ...state,
        createPaymentMethodLoading: true,
      };

    case "DONE_CREATE_PAYMENT_METHOD":
      return {
        ...state,
        createPaymentMethodLoading: false,
      };

    case "ERROR_CREATE_PAYMENT_METHOD":
      return {
        ...state,
        createPaymentMethodLoading: false,
      };

    case "START_CREATE_SUBSCRIPTION":
      return {
        ...state,
        makePaymentLoading: true,
      };

    case "DONE_CREATE_SUBSCRIPTION":
      return {
        ...state,
        makePaymentLoading: false,
      };
    default:
      return state;
  }
};

export default Payment;
