
const initState = {
    loading: false,
    status: 'idle',
    user: {},
    openLoginModal: false,
    openOtpModal: false
}

const researchMetrix = (state = initState, action) => {
    switch (action.type) {
        case 'START_SAVE_ANSWERS':
            return {
                ...state,
                loading: true,
                status: 'pending'
            }
        case 'DONE_SAVE_ANSWERS':
            return {
                ...state,
                loading: false,
                user: action.data
            }
        case 'RESET_STATUS':
            return {
                ...state,
                status: 'idle'
            }
        case 'STOP_LOADING':
            return {
                ...state,
                loading: false,
            }
        case 'OPEN_LOGIN_MODAL':
            return {
                ...state,
                openLoginModal: true
            }
        case 'CLOSE_LOGIN_MODAL':
            return {
                ...state,
                openLoginModal: false
            }
        case 'OPEN_OTP_MODAL':
            return {
                ...state,
                openOtpModal: true
            }
        case 'CLOSE_OTP_MODAL':
            return {
                ...state,
                openOtpModal: false
            }
        default:
            return state;
    }
}

export default researchMetrix