/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState }from "react";
import { connect } from "react-redux";
import { Menu, Layout, Typography, Dropdown } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CaretDownFilled,
  QuestionCircleFilled,
  SearchOutlined
} from "@ant-design/icons";
import Logo from "./Logo";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import NavSearch  from './NavSearch';
import SearchInput from "./NavSearch/SearchInput";
import { NavProfile } from "./NavProfile";
import { NavNotification } from "./NavNotification";

const { Header } = Layout;
const { Text } = Typography;

export const HeaderNav = (props) => {
console.log("🚀 ~ file: HeaderNav.js ~ line 29 ~ HeaderNav ~ props", props)
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
  } = props;

  const [searchActive, setSearchActive] = useState(false)

  const onSearchActive = () => {
    setSearchActive(true)
  }

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = utils.getColorContrast(headerNavColor);
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };
  return (
    <Header
      className={`app-header ${mode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <Menu mode="horizontal">
              {/* <Menu.Item key="0">
                <Logo logoType={mode} />
              </Menu.Item> */}
              {
                isNavTop && !isMobile ?
                null
                :
                <Menu.Item key="0" onClick={() => {onToggle()}}>
                  {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                </Menu.Item>
              }
              {/* {
                isMobile ?
                <Menu.Item key="1" onClick={() => {onSearchActive()}}>
                  <SearchOutlined />
                </Menu.Item>
                :
                <Menu.Item key="1" style={{cursor: 'auto'}}>
                  <SearchInput isMobile={isMobile} />
                </Menu.Item>
              } */}
            </Menu>
          </div>
          <div className="nav-right">
            <NavProfile className="pl-3" />
            <NavNotification className="pl-3"/>
          </div>
          <NavSearch active={searchActive} close={onSearchClose}/>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
