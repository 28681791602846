import React, { useState, useEffect, useRef } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import io from "socket.io-client";
import FormattingService from "../../services/FormattingService";
import { API_BASE_URL } from "configs/AppConfig";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

const getNotificationBody = (list) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            {/* <div className="pr-3">
            {item.img? <Avatar src={`/img/avatars/${item.img}`} /> : <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />}
          </div> */}
            <div className="mr-3">
              <div className="font-weight-bold text-dark mb-1">
                {item.title}{" "}
              </div>
              <span className="text-gray-light">{item.notification}</span>
            </div>
            <small className="ml-auto">
              {moment(item.createdAt).format("HH:mm")}
            </small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};
export const NavNotification = (props) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const socketRef = useRef();

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  useEffect(() => {
    const payload = FormattingService.parseJwt();
    dispatch({
      type: "START_GET_NEW_NOTIFICATIONS",
      data: { email: payload.username, userId: payload.userId },
    });
  }, []);

  useEffect(() => {
    setData((state) => [...state, ...notifications]);
  }, [notifications]);

  useEffect(() => {
    socketRef.current = io.connect(API_BASE_URL);
    const payload = FormattingService.parseJwt();
    socketRef.current.on(payload.username, (data) => {
      setData((state) => [...state, data]);
    });
    socketRef.current.on(payload.userId, (data) => {
      setData((state) => [...state, data]);
    });
    return () => socketRef.current.disconnect();
  }, []);

  const clearNotifications = () => {
    setData([]);
    const payload = FormattingService.parseJwt();
    dispatch({
      type: "START_CLEAR_NOTIFICATIONS",
      data: { email: payload.username, userId: payload.userId },
    });
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button type="link" onClick={clearNotifications} size="small">
          Clear{" "}
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
      {/* {
        data.length > 0 ? 
        <div className="nav-notification-footer">
          <a className="d-block" href="#/">View all</a>
        </div>
        :
        null
      } */}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
      overlayStyle={{ position: "fixed" }}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
