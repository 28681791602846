import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import IcoMoonIcon from "components/util-components/IcoMoonIcon";
import FormattingService from "services/FormattingService";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const hasPermission = (permissionList) => {
  const payload = FormattingService.parseJwt();
  return permissionList.includes(parseInt(payload["custom:role_id"]));
};

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    variant,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig[variant ? variant : "main"].map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <span className="anticon">
                        <IcoMoonIcon
                          icon={subMenuFirst?.icon}
                          size={20}
                          className="mr-3"
                        />
                      </span>
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) =>
                    hasPermission(subMenuSecond.permission) ? (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <span className="anticon">
                            <IcoMoonIcon
                              icon={subMenuSecond?.icon}
                              size={20}
                              className="mr-3"
                            />
                          </span>
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ) : null
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <span className="anticon">
                      <IcoMoonIcon
                        icon={subMenuFirst.icon}
                        size={20}
                        className="mr-3"
                      />
                    </span>
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path}
                  />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? (
              <span className="anticon">
                <IcoMoonIcon icon={menu?.icon} size={20} className="mr-3" />
              </span>
            ) : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization, variant } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig[variant ? variant : "main"].map((menu) =>
        hasPermission(menu.permission) ? (
          menu.submenu.length > 0 ? (
            <SubMenu
              key={menu.key}
              popupClassName="top-nav-menu"
              title={
                <span>
                  {menu.icon ? (
                    <span className="anticon">
                      <IcoMoonIcon
                        icon={menu?.icon}
                        size={20}
                        className="mr-3"
                      />
                    </span>
                  ) : null}
                  <span>{setLocale(localization, menu.title)}</span>
                </span>
              }
            >
              {menu.submenu.map((subMenuFirst) =>
                hasPermission(subMenuFirst.permission) ? (
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      key={subMenuFirst.key}
                      icon={
                        subMenuFirst.icon ? (
                          <span className="anticon">
                            <IcoMoonIcon
                              icon={subMenuFirst?.icon}
                              size={20}
                              className="mr-3"
                            />
                          </span>
                        ) : null
                      }
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) =>
                        hasPermission(subMenuSecond.permission) ? (
                          <Menu.Item key={subMenuSecond.key}>
                            <span>
                              {setLocale(localization, subMenuSecond.title)}
                            </span>
                            <Link to={subMenuSecond.path} />
                          </Menu.Item>
                        ) : null
                      )}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? (
                        <span className="anticon">
                          <IcoMoonIcon
                            icon={subMenuFirst?.icon}
                            size={20}
                            className="mr-3"
                          />
                        </span>
                      ) : null}
                      <span>{setLocale(localization, subMenuFirst.title)}</span>
                      <Link to={subMenuFirst.path} />
                    </Menu.Item>
                  )
                ) : null
              )}
            </SubMenu>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? (
                <span className="anticon">
                  <IcoMoonIcon icon={menu?.icon} size={20} className="mr-3" />
                </span>
              ) : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? <Link to={menu.path} /> : null}
            </Menu.Item>
          )
        ) : null
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
