import {
  APP_PREFIX_PATH,
  DOCUMENT_REQ_PREFIX_PATH,
  USER_PREFIX_PATH,
} from "configs/AppConfig";
import { USER_ROLES } from "configs/UserRoleConfig";

const routeConfig = [
  {
    key: "clientdashboard",
    path: `${APP_PREFIX_PATH}/clientdashboard`,
    component: `./client-dashboard/Dashboard`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "profile",
    path: `${APP_PREFIX_PATH}/profile`,
    component: `./profile/Profile`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  // {
  //   key: "dashboard",
  //   path: `${APP_PREFIX_PATH}`,
  //   component: `./dashboard/Dashboard`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: true,
  // },
  {
    key: "organization",
    path: `${APP_PREFIX_PATH}/scorecard`,
    component: `./organizations/all/AllScoreCard`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "organization",
    path: `${APP_PREFIX_PATH}/score-card/create`,
    component: `./organizations/all/LocationAppUsers`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "tasks",
    path: `${APP_PREFIX_PATH}/tasks`,
    component: `./organizations/all/AllTasks`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "clientTasks",
    path: `${APP_PREFIX_PATH}/clientTasks`,
    component: `./client-task/ClientTask`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "expedite",
    path: `${APP_PREFIX_PATH}/expedite`,
    component: `./expedite/Expedite`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "factfinding",
    path: `${APP_PREFIX_PATH}/factfinding`,
    component: `./factfinding/FactFinding`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "application",
    path: `${APP_PREFIX_PATH}/applications`,
    component: `./application/all/AllApplications`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "researchmatrix",
    path: `${APP_PREFIX_PATH}/researchmatrix`,
    component: `./researchmatrix/ResearchMatrix`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "budget",
    path: `${APP_PREFIX_PATH}/budget`,
    component: `./budget/Budget`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "scoring",
    path: `${APP_PREFIX_PATH}/scoring`,
    component: `./scoring/Scoring`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  // {
  //   key: "agentclients",
  //   path: `${APP_PREFIX_PATH}/agentclients`,
  //   component: `./agent-clients/Clients`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: false,
  // },
  //-----------staff portal-------------
  // {
  //   key: "general",
  //   path: `${APP_PREFIX_PATH}/general`,
  //   component: `./general/General`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: false,
  // },
  // {
  //   key: "agentapplication",
  //   path: `${APP_PREFIX_PATH}/agentapplication`,
  //   component: `./agent-applications/Applications`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: false,
  // },
  // {
  //   key: "bumpUpRequest",
  //   path: `${APP_PREFIX_PATH}/bumpUpRequest`,
  //   component: `./bumpUpRequest/BumpUpRequest`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: false,
  // },
  // {
  //   key: "agentcommissions",
  //   path: `${APP_PREFIX_PATH}/agentcommissions`,
  //   component: `./agent-commissions/Commissions`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: false,
  // },
  // {
  //   key: "agentprofile",
  //   path: `${APP_PREFIX_PATH}/agentprofile`,
  //   component: `./agent-profile/Profile`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: false,
  // },
  // {
  //   key: "staffagents",
  //   path: `${APP_PREFIX_PATH}/staffagents`,
  //   component: `./staff-agents/Agents`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: true,
  // },
  // {
  //   key: "addagent",
  //   path: `${APP_PREFIX_PATH}/staffagents/new`,
  //   component: `./staff-addAgent/AddAgent`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: true,
  // },
  // {
  //   key: "staffagentprofile",
  //   path: `${APP_PREFIX_PATH}/staffagents/:id`,
  //   component: `./staff-agentProfile/AgentProfile`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: true,
  // },
  // {
  //   key: "systemConfigurations",
  //   path: `${APP_PREFIX_PATH}/systemConfigurations`,
  //   component: `./systemConfigurations/SystemConfigurations`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: false,
  // },
  {
    key: "documents",
    path: `${APP_PREFIX_PATH}/documents`,
    component: `./documents/Documents`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: true,
  },
  {
    key: "adddocuments",
    path: `${APP_PREFIX_PATH}/documents/new`,
    component: `./createDocument/CreateDocument`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: true,
  },
  {
    key: "uploaddocuments",
    path: `${APP_PREFIX_PATH}/uploaddocuments`,
    component: `./uploadDocuments/UploadDocuments`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  {
    key: "documenttasklist",
    path: `${APP_PREFIX_PATH}/documenttasklist`,
    component: `./documentTasks/TaskList`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    exact: false,
  },
  // {
  //   key: "driverslicence",
  //   path: `${APP_PREFIX_PATH}/documents/driverslicence`,
  //   component: `./documentRequirements/DocumentRequirements`,
  //   permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
  //   exact: false,
  // },
];

const documentRequirementRouteConfig = [
  {
    key: "drivers-licence",
    path: `${DOCUMENT_REQ_PREFIX_PATH}/drivers-licence`,
    component: `./documentRequirements/DocumentRequirements`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: true,
  },
  {
    key: "pay-slip",
    path: `${DOCUMENT_REQ_PREFIX_PATH}/pay-slip`,
    component: `./documentRequirements/DocumentRequirements`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: false,
  },
  {
    key: "tax-invoice",
    path: `${DOCUMENT_REQ_PREFIX_PATH}/tax-invoice`,
    component: `./documentRequirements/DocumentRequirements`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: false,
  },
  {
    key: "bank-statement",
    path: `${DOCUMENT_REQ_PREFIX_PATH}/bank-statement`,
    component: `./documentRequirements/DocumentRequirements`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: false,
  },
  {
    key: "dividend-statement",
    path: `${DOCUMENT_REQ_PREFIX_PATH}/dividend-statement`,
    component: `./documentRequirements/DocumentRequirements`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: false,
  },
  {
    key: "financial-statement",
    path: `${DOCUMENT_REQ_PREFIX_PATH}/financial-statement`,
    component: `./documentRequirements/DocumentRequirements`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: false,
  },
  {
    key: "property-deed",
    path: `${DOCUMENT_REQ_PREFIX_PATH}/property-deed`,
    component: `./documentRequirements/DocumentRequirements`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: false,
  },
];

const clientProfileRouteConfig = [
  {
    key: "clientdashboard",
    path: `${USER_PREFIX_PATH}`,
    component: `./client-dashboard/Dashboard`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: true,
  },
  {
    key: "expedite",
    path: `${USER_PREFIX_PATH}/expedite`,
    component: `./expedite/Expedite`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: true,
  },
  {
    key: "uploaddocuments",
    path: `${USER_PREFIX_PATH}/uploaddocuments`,
    component: `./uploadDocuments/UploadDocuments`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: true,
  },
  {
    key: "tasks",
    path: `${USER_PREFIX_PATH}/tasks`,
    component: `./documentTasks/TaskList`,
    permission: [USER_ROLES.SUPER_ADMIN, USER_ROLES.USER],
    expect: true,
    exact: true,
  },
];

export {
  routeConfig,
  documentRequirementRouteConfig,
  clientProfileRouteConfig,
};
