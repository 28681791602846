import moment from 'moment'
import { DEFAULT_PAGE_SIZE } from 'constants/DefaultConstant'

const FormattingService = {}

FormattingService.dateFormat = (date, format) => {
    return (date  === '0001-01-01T00:00:00' ? 'N/A' : moment(date).format(format))
}

FormattingService.truncate = (str) => {
    return str?.length > 30 ? str.substring(0, 27) + '...' : str
}

FormattingService.parseJwt = () => {
    const token = localStorage.getItem('AUTH_TOKEN')
    var base64Url = token?.split('.')[1];
    var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload)
}

FormattingService.sortDirection = (sortDirection) => {
    switch (sortDirection) {
        case 'ascend':
          return 'ASC'
        case 'descend':
          return 'DESC'
        default:
          return null
      }
}

FormattingService.calcRecordCount = (page, currentPageRecordCount) => {
    return currentPageRecordCount === DEFAULT_PAGE_SIZE ?
        DEFAULT_PAGE_SIZE*page + 1
        : DEFAULT_PAGE_SIZE*page
  }

export default FormattingService