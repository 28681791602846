import { all, put, fork, call, takeEvery, takeLeading } from 'redux-saga/effects';
import ApplicationService from 'services/ApplicationService';
import {message} from 'antd'


  export function* getApplicationsSaga() {
    yield takeEvery('START_GET_APPLICATIONS', function* () {
          try {
            const response = yield call(ApplicationService.getApplications);
              
            yield put({type: 'DONE_GET_APPLICATIONS',data: response.data})
          } catch (error) {
            console.log('Error Message', error.message);
            yield put({type: 'STOP_LOADING'})
            message.error(error.message);
          }
      });
  }

  export function* assignScoreCardSaga() {
    yield takeLeading('START_ASSIGN_SCORE_CARD', function* (input) {
          try {
            yield call(ApplicationService.assignScoreCard,input.data);
            input.closeModal()
          } catch (error) {
            console.log('Error Message', error.message);
            yield put({type: 'STOP_LOADING'})
            message.error(error.message);
          }
      });
  }

  export default function* rootSaga() {
    yield all([
        fork(getApplicationsSaga),
        fork(assignScoreCardSaga),
    ]);
  }