import { Button, Col, Row, Tooltip } from "antd";
import React from "react";
import { MinusOutlined, EditFilled } from "@ant-design/icons";

const ReadOnlyRowAssests = ({
  contact,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <Row gutter={2}>
      <Col span={5}>{contact.descriptionAsst}</Col>
      <Col span={4}>{contact.ownerAsst}</Col>
      <Col span={3}>{contact.currentValueAsst}</Col>
      <Col span={3}>{contact.typeAsst}</Col>
      <Col span={3}>
        <Tooltip title="Edit" color="#FB8C26">
          <Button
            size="medium"
            style={{ border: "none" }}
            onClick={(event) => handleEditClick(event, contact)}
          >
            <EditFilled />
          </Button>
        </Tooltip>
        <Button
          size="small"
          style={{
            width: "30px",
            height: "30px",
            float: "right",
            borderColor: "#EF6225",
            color: "#EF6225",
            borderRadius: "4px",
            marginRight: "35px",
            padding: "8px",
          }}
          onClick={() => handleDeleteClick(contact.idAsst)}
        >
          <MinusOutlined />
        </Button>
      </Col>
    </Row>
  );
};

export default ReadOnlyRowAssests;
