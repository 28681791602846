import axios from "axios";

const paymentService = {};

paymentService.createCustomer = async (data) => {
  return await axios.post("/stripes/createCustomer", data);
};

paymentService.attachPaymentMethod = async (data) => {
  return await axios
    .post("/stripes/payment-methods/attach", data)
    .catch((error) => {
      console.log(
        "🚀 ~ file: Payment.js ~ line 11 ~ returnawaitaxios.post ~ error",
        error
      );
      throw new Error(error);
    });
};

paymentService.setDefaultPaymentMethod = async (data) => {
  return await axios.put("/stripes/payment-methods/set/default", data);
};

paymentService.createProduct = async (data) => {
  return await axios.post("/stripes/products/create", data);
};

paymentService.createPlan = async (data) => {
  return await axios.post("/stripes/plans/create", data);
};

paymentService.createSubscription = async (data) => {
  return await axios.post("/stripes/subscriptions/create", data);
};

export default paymentService;
