import axios from "axios"

const applicationService = {}

applicationService.getApplications = async () =>  {
  return await axios.get("/applications", {})
}


applicationService.assignScoreCard = async (data) =>  {
  return await axios.patch(`applications/scorecard/assign`, data)
}

export default applicationService