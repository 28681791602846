import {
  all,
  takeLeading,
  put,
  fork,
  call,
  takeEvery,
  select,
} from "redux-saga/effects";
import BankService from "services/BankService";
import { message } from "antd";

export function* createbankUserSaga() {
  yield takeLeading("START_CREATE_USER", function* (input) {
    console.log(
      "🚀 ~ file: Bank.js ~ line 7 ~ yieldtakeLeading ~ input",
      input
    );
    try {
      const token = yield call(BankService.getToken);
      let inputData = {};
      if (input?.data?.data?.email) {
        inputData = {
          id: input.data.data.id || "",
          email: input.data.data.email || "",
          mobile: input.data.data.mobilePhoneNumber || "",
        };
      } else {
        let user = yield select((state) => state.user.user);
        inputData = {
          id: user.id || "",
          email: user.email || "",
          mobile: user.mobilePhoneNumber || "",
        };
      }
      if (inputData?.id !== "") {
        console.log(
          "🚀 ~ file: Bank.js ~ line 19 ~ yieldtakeLeading ~ inputData",
          inputData
        );

        const user = yield call(BankService.createUser, inputData);
        console.log(
          "🚀 ~ file: Bank.js ~ line 21 ~ yieldtakeLeading ~ user",
          user
        );
        yield put({ type: "DONE_CREATE_USER" });
        // navigate to next page if success
        input.data.navigator.push("/client/bank/select", user.data);
      } else {
        message.error("Error in setup user 1");
      }
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_BANK_LOADING" });
      message.error(error.message);
    }
  });
}

export function* getInstitutionsSaga() {
  yield takeEvery("START_GET_INSTITUTIONS", function* () {
    try {
      const hideLoading = message.loading("loading..", 0);
      const institution = yield call(BankService.getInstitutions);
      hideLoading();
      yield put({ type: "DONE_GET_INSTITUTIONS", data: institution.data });
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_BANK_LOADING" });
      message.error(error.message);
    }
  });
}

export function* bankLoginSaga() {
  yield takeEvery("START_BANK_LOGIN", function* (input) {
    console.log("🚀 ~ file: Bank.js ~ line 49 ~ yieldtakeEvery ~ input", input);
    try {
      const userId = input.data.userId;
      const history = input.history;
      console.log(
        "🚀 ~ file: Bank.js ~ line 52 ~ yieldtakeEvery ~ userId",
        userId
      );
      const job = yield call(
        BankService.connectInstitution,
        input.data,
        userId
      );
      console.log("🚀 ~ file: Bank.js ~ line 91 ~ yieldtakeEvery ~ job", job);
      const { jobId } = job.data;
      const jobResponse = yield call(BankService.verifyConnection, jobId);
      console.log(
        "🚀 ~ file: Bank.js ~ line 92 ~ yieldtakeEvery ~ jobResponse",
        jobResponse
      );
      const credentials = jobResponse.data.steps.find(
        (step) =>
          step.title === "verify-credentials" && step.status === "success"
      );
      console.log(
        "🚀 ~ file: Bank.js ~ line 102 ~ yieldtakeEvery ~ credentials",
        credentials
      );
      if (!credentials) throw new Error("Invalid Credentials!");
      yield put({ type: "DONE_BANK_LOGIN" });
      let selectedBank = yield select((state) => state.bank.institutions);
      console.log(
        "🚀 ~ file: Bank.js ~ line 57 ~ yieldtakeEvery ~ selectedBank",
        selectedBank
      );
      if (selectedBank.length > 0) {
        const bankId = input.data.institute;
        selectedBank = selectedBank.find((bank) => bank.id === bankId);
        console.log(
          "🚀 ~ file: Bank.js ~ line 61 ~ yieldtakeEvery ~ selectedBank",
          selectedBank
        );
        const selectedBankDetail = { selectedBank, userId };
        history.push("/client/bank/login/success", selectedBankDetail);
      }
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_BANK_LOADING" });
      message.error(error.message);
    }
  });
}

export function* genarateDocuemntsSaga() {
  yield takeLeading("START_GENERATE_DOC", function* (input) {
    try {
      let user = yield select((state) => state.user.user);
      console.log(
        "🚀 ~ file: Bank.js ~ line 81 ~ yieldtakeLeading ~ user",
        user
      );
      const inputData = {
        userId: user.id,
      };
      console.log(
        "🚀 ~ file: Bank.js ~ line 84 ~ yieldtakeLeading ~ inputData",
        inputData
      );
      const response = yield call(BankService.createUserSignature, inputData);
      console.log(
        "🚀 ~ file: Bank.js ~ line 82 ~ yieldtakeLeading ~ response",
        response
      );
      if (response.data.status === "success") {
        // yield call(BankService.signatureSubmit, inputData);
        message.success("Document has been sent for signature");
      } else {
        message.error("Signature creation failed!");
      }
      yield put({ type: "DONE_GENERATE_DOC" });
      input.history.push("/client/bank/confirmation");
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_BANK_LOADING" });
      message.error(error.message);
    }
  });
}

export function* createPaymentUserSaga() {
  yield takeLeading("START_CREATE_PAYMENT_USER", function* (input) {
    try {
      let user = yield select((state) => state.user.user);

      const inputData = {
        email: user.email,
        name: user.firstName,
        description: "First stripe",
      };
      yield call(BankService.createCustomer, inputData);
      yield put({ type: "DONE_CREATE_PAYMENT_USER" });
      input.history.push("/client/bank/payment/success");
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_BANK_LOADING" });
      message.error(error.message);
    }
  });
}

export function* eligibilityCalcSaga() {
  yield takeLeading("START_CALC_ELIGIBILITY", function* (input) {
    try {
      let eligibility = {
        housing: false,
        investment: false,
        apartment: false,
      };
      let user = yield select((state) => state.user.user);
      console.log(
        "🚀 ~ file: Bank.js ~ line 133 ~ yieldtakeLeading ~ user",
        user
      );
      yield put({ type: "START_BANK_LOADING" });

      const apartmentEligibility = yield call(
        BankService.getApartmentEligibility,
        user?.id
      );
      const investmentEligibility = yield call(
        BankService.getInvestmentEligibility,
        user?.id
      );
      const housingEligibility = yield call(
        BankService.getHousingEligibility,
        user?.id
      );

      if (apartmentEligibility.data === "ELIGIBLE") {
        eligibility.apartment = true;
      }
      if (investmentEligibility.data === "ELIGIBLE") {
        eligibility.investment = true;
      }
      if (housingEligibility.data === "ELIGIBLE") {
        eligibility.housing = true;
      }
      console.log(
        "🚀 ~ file: Bank.js ~ line 132 ~ yieldtakeLeading ~ eligibility",
        eligibility
      );

      yield put({ type: "DONE_CALC_ELIGIBILITY", data: eligibility });
      yield put({ type: "STOP_BANK_LOADING" });
      //check
      if (
        eligibility.apartment ||
        eligibility.housing ||
        eligibility.investment
      ) {
        input.history.push("/client/eligible");
      } else {
        input.history.push("/client/not/eligible");
      }
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_BANK_LOADING" });
      message.error(error.message);
    }
  });
}

export function* paymentCalcSaga() {
  yield takeLeading("START_CALC_PAYMENT", function* (input) {
    try {
      let user = yield select((state) => state.user.user);
      console.log(
        "🚀 ~ file: Bank.js ~ line 133 ~ yieldtakeLeading ~ user",
        user
      );
      yield put({ type: "START_BANK_LOADING" });
      const paymentAmount = yield call(
        BankService.paymentCalculation,
        user?.id
      );
      if (paymentAmount.data) {
        const payment = {
          // total: paymentAmount?.data?.payment || 0,
          total: 8000,
          // noOfMonths: paymentAmount?.data?.noOfMonths || 1,
          noOfMonths: 20,
          subValue: 350,
        };
        yield put({ type: "DONE_CALC_PAYMENT", data: payment });
        input.history.push("/client/eligible/view");
      } else {
        message.error("Something went wrong.Try again!");
      }
      yield put({ type: "STOP_BANK_LOADING" });
    } catch (error) {
      console.log("Error Message", error.message);
      yield put({ type: "STOP_BANK_LOADING" });
      message.error(error.message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createbankUserSaga),
    fork(getInstitutionsSaga),
    fork(bankLoginSaga),
    fork(genarateDocuemntsSaga),
    fork(createPaymentUserSaga),
    fork(eligibilityCalcSaga),
    fork(paymentCalcSaga),
  ]);
}
